import React, { useState, useEffect } from "react";
import "./RegistrationPage.css"; // External CSS file for styling
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com"; // Import EmailJS

const RegistrationPage = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  // Fields for step 1
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userType, setUserType] = useState("None");

  // Fields for step 2 (OTP)
  const [otp, setOtp] = useState("");
  const [generatedOtp, setGeneratedOtp] = useState(""); // Store generated OTP
  const [otpValid, setOtpValid] = useState(false);
  const [otpTimer, setOtpTimer] = useState(60); // Timer set to 60 seconds
  const [resendDisabled, setResendDisabled] = useState(true);

  useEffect(() => {
    // Start the countdown when the user reaches step 3
    let countdown;
    if (step === 2 && otpTimer > 0) {
      countdown = setInterval(() => {
        setOtpTimer((prev) => prev - 1);
      }, 1000);
    }

    if (otpTimer === 0) {
      clearInterval(countdown);
      setOtpValid(false); // Invalidate OTP after timer ends
      setResendDisabled(false); // Enable resend button
    }

    return () => clearInterval(countdown);
  }, [otpTimer, step]);

  // Generate and send OTP to user's email
  const generateOtp = () => {
    const otp = Math.floor(10000 + Math.random() * 90000); // 5-digit OTP
    setGeneratedOtp(otp.toString());

    // Send OTP via EmailJS
    const templateParams = {
      to_email: email,
      otp: otp,
    };

    emailjs.send("service_lxfknhj", "template_zfhwa7r", templateParams, "ecdAB8yX70VNCKtZt")
      .then((response) => {
        
      })
      .catch((error) => {
        console.error("Error sending OTP: ", error);
        alert("Failed to send OTP. Please try again.");
      });
  };

  // Handle OTP form submission
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
  
    // Check if OTP is correct
    if (otp === generatedOtp) {
      alert("OTP verified successfully!");
  
      // Now store the user data in the database (after OTP validation)
      const userData = { name, email, password, userType };
  
      try {
        const response = await fetch("https://back.startupconnectz.com/api/users/register", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(userData),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          setStep(3); // Proceed to success message
        } else {
          alert(data.message || "Registration failed!");
        }
      } catch (error) {
        console.error("Error during registration:", error);
        alert("An error occurred. Please try again.");
      }
    } else {
      alert("Invalid OTP. Please try again.");
    }
  };

  // Handle form submission (Step 1)
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if passwords match
    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }
  
    // Store user details temporarily in state (not saved to DB yet)
    const userData = { name, email, password, userType };
  
    // Generate OTP and send it to the user’s email
    generateOtp();
  
    // Move to OTP verification step
    alert("OTP has been sent to your email.");
    setStep(2);
  };

  // Handle resend OTP
  const handleResendOtp = () => {
    generateOtp(); // Resend OTP
    setOtpTimer(60); // Restart the timer
    setOtpValid(true); // OTP is valid again
    setResendDisabled(true); // Disable resend button
  };

  return (
    <div className="registration-container556">
      <div className="form-container556">
        <div className="left-section556">
          <img src="/images/android-chrome-512x512.png" alt="startup-connect-logo"  height="150px" width="200px" />
          <h2>Connect to Startup</h2>
          <p>Feel Free To Connect</p>
          <p>Already have an account? <a href="/login">Login here</a></p>
          <button className="google-login556">Login with Google</button>
          <br></br>

        </div>

        <div className="right-cover556">
          <div className="right-section556">
            <h2>Registration Form</h2>
            {step === 1 && (
              <form onSubmit={handleSubmit}>
                <table border="none">
                  <div className="form-group556">
                    <tr>
                      <td><label htmlFor="name">Name</label></td>
                      <td><input
                        type="text"
                        id="name556"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      /></td>
                    </tr>
                    <tr>
                      <td><label htmlFor="email">Email Address</label></td>
                      <td><input
                        type="email"
                        id="email556"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      /></td>
                    </tr>
                  </div>
                  <div className="form-group556">
                    <tr>
                      <td><label htmlFor="password">Password</label></td>
                      <td><input
                        type="password"
                        id="password556"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      /></td>
                    </tr>
                  </div>
                  <div className="form-group556">
                    <tr>
                      <td><label htmlFor="confirmPassword">Confirm Password</label></td>
                      <td><input
                        type="password"
                        id="confirmPassword556"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      /></td>
                    </tr>
                  </div>
                  <div className="form-group556">
                    <tr>
                      <td>
                        <select value={userType} onChange={(e) => setUserType(e.target.value)}>
                          <option value="None">None</option> {/* Explicit default value */}
                          <option value="Startup">Startup</option>
                          <option value="Mentor">Mentor</option>
                          <option value="Investor">Investor</option>
                        </select>
                      </td>
                    </tr>
                  </div>


                </table>
                <button type="submit" className="register-button556">Register</button>
              </form>
            )}
            {step === 2 && (
              <form onSubmit={handleOtpSubmit} className="otpform556">
                <center><div className="form-group556">
                  <p id="otp-sent556">OTP has been sent to your mail ID!</p>
                  <center>
                    <input
                      type="text"
                      id="otp556"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP here"
                      required
                    />
                  </center>
                </div></center>
                <button type="submit" className="verify-button556">Verify OTP</button>

                <center><p id="otp-p556">
                  {otpTimer > 0
                    ? `Resend OTP in ${otpTimer}s`
                    : "Didn't receive an OTP?"}
                </p></center>
                <button
                  type="button"
                  className="resend-button123556"
                  onClick={handleResendOtp}
                  disabled={resendDisabled}
                >
                  Resend OTP
                </button>
              </form>
            )}
            {/* Success Message Step 3 */}
            {step === 3 && (
              <div className="success-message556">
                <img src="https://media.tenor.com/WsmiS-hUZkEAAAAj/verify.gif" height="200px" width="200px" alt="sucess.gif" />
                <h3 style={{ color: "green" }}>Registration Successful!</h3>
                <button onClick={() => navigate("/login")} className="login-button556">
                  Proceed to Login
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationPage;

// import React, { useState, useEffect } from "react";
// import "./RegistrationPage.css"; // External CSS file for styling
// import { useNavigate } from "react-router-dom";
// import emailjs from "emailjs-com"; // Import EmailJS

// const RegistrationPage = () => {
//   const [step, setStep] = useState(1);
//   const navigate = useNavigate();
//   // Fields for step 1
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [userType, setUserType] = useState("None");

//   // Fields for step 2 (OTP)
//   const [otp, setOtp] = useState("");
//   const [generatedOtp, setGeneratedOtp] = useState(""); // Store generated OTP
//   const [otpValid, setOtpValid] = useState(false);
//   const [otpTimer, setOtpTimer] = useState(60); // Timer set to 60 seconds
//   const [resendDisabled, setResendDisabled] = useState(true);

//   useEffect(() => {
//     // Start the countdown when the user reaches step 3
//     let countdown;
//     if (step === 2 && otpTimer > 0) {
//       countdown = setInterval(() => {
//         setOtpTimer((prev) => prev - 1);
//       }, 1000);
//     }

//     if (otpTimer === 0) {
//       clearInterval(countdown);
//       setOtpValid(false); // Invalidate OTP after timer ends
//       setResendDisabled(false); // Enable resend button
//     }

//     return () => clearInterval(countdown);
//   }, [otpTimer, step]);

//   const generateOtp = () => {
//     const otp = Math.floor(10000 + Math.random() * 90000).toString(); // 5-digit OTP
//     setGeneratedOtp(otp);
  
//     const templateParams = {
//       to_email: email,
//       otp: otp,
//     };
  
//     emailjs
//       .send("service_lxfknhj", "template_zfhwa7r", templateParams, "ecdAB8yX70VNCKtZt")
//       .then((response) => {
//         console.log("Email sent successfully:", response);
//         alert("OTP has been sent to your email.");
//       })
//       .catch((error) => {
//         console.error("Error sending OTP:", error);
//         alert("Failed to send OTP. Please try again.");
//       });
//   };
  
//   // Handle OTP form submission
//   const handleOtpSubmit = async (e) => {
//     e.preventDefault();

//     if (otp === generatedOtp) { // Compare entered OTP with generated OTP
//       alert("OTP verified successfully!");
//       setStep(3); // Proceed to next step after OTP verification
//     } else {
//       alert("Invalid OTP. Please try again.");
//     }
//   };


//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (password !== confirmPassword) {
//       alert("Passwords do not match!");
//       return;
//     }
  
//     console.log("User email for OTP:", email); // Debugging line
  
//     const formData = { name, email, password, userType };
  
//     try {
//       const response = await fetch("https://back.startupconnectz.com/api/users/register", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(formData),
//       });
  
//       const data = await response.json();
  
//       if (response.ok) {
//         alert("Registration successful! OTP has been sent.");
//         setStep(2);
//         generateOtp();
//         setOtpTimer(60);
//         setResendDisabled(true);
//       } else {
//         alert(data.message || "Registration failed!");
//       }
//     } catch (error) {
//       console.error("Error during registration:", error);
//       alert("An error occurred. Please try again.");
//     }
//   };
  

//   // Handle resend OTP
//   const handleResendOtp = () => {
//     generateOtp(); // Resend OTP
//     setOtpTimer(60); // Restart the timer
//     setOtpValid(true); // OTP is valid again
//     setResendDisabled(true); // Disable resend button
//   };


//   return (
//     <div className="registration-container556">
//       <div className="form-container556">
//         <div className="left-section556">
//           <img src="/images/android-chrome-512x512.png" alt="startup-connect-logo"  height="150px" width="200px" />
//           <h2>Connect to Startup</h2>
//           <p>Feel Free To Connect</p>
//           <p>Already have an account? <a href="/login">Login here</a></p>
//           <button className="google-login556">Login with Google</button>
//           <br></br>

//         </div>

//         <div className="right-cover556">
//           <div className="right-section556">
//             <h2>Registration Form</h2>
//             {step === 1 && (
//               <form onSubmit={handleSubmit}>
//                 <table border="none">
//                   <div className="form-group556">
//                     <tr>
//                       <td><label htmlFor="name">Name</label></td>
//                       <td><input
//                         type="text"
//                         id="name556"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                         required
//                       /></td>
//                     </tr>
//                     <tr>
//                       <td><label htmlFor="email">Email Address</label></td>
//                       <td><input
//                         type="email"
//                         id="email556"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                       /></td>
//                     </tr>
//                   </div>
//                   <div className="form-group556">
//                     <tr>
//                       <td><label htmlFor="password">Password</label></td>
//                       <td><input
//                         type="password"
//                         id="password556"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                       /></td>
//                     </tr>
//                   </div>
//                   <div className="form-group556">
//                     <tr>
//                       <td><label htmlFor="confirmPassword">Confirm Password</label></td>
//                       <td><input
//                         type="password"
//                         id="confirmPassword556"
//                         value={confirmPassword}
//                         onChange={(e) => setConfirmPassword(e.target.value)}
//                         required
//                       /></td>
//                     </tr>
//                   </div>
//                   <div className="form-group556">
//                     <tr>
//                       <td>
//                         <select value={userType} onChange={(e) => setUserType(e.target.value)}>
//                           <option value="None">None</option> {/* Explicit default value */}
//                           <option value="Startup">Startup</option>
//                           <option value="Mentor">Mentor</option>
//                           <option value="Investor">Investor</option>
//                         </select>
//                       </td>
//                     </tr>
//                   </div>


//                 </table>
//                 <button type="submit" className="register-button556">Register</button>
//               </form>
//             )}
//             {step === 2 && (
//               <form onSubmit={handleOtpSubmit} className="otpform556">
//                 <center><div className="form-group556">
//                   <p id="otp-sent556">OTP has been sent to your mail ID!</p>
//                   <center>
//                     <input
//                       type="text"
//                       id="otp556"
//                       value={otp}
//                       onChange={(e) => setOtp(e.target.value)}
//                       placeholder="Enter OTP here"
//                       required
//                     />
//                   </center>
//                 </div></center>
//                 <button type="submit" className="verify-button556">Verify OTP</button>

//                 <center><p id="otp-p556">
//                   {otpTimer > 0
//                     ? Resend OTP in ${otpTimer}s
//                     : "Didn't receive an OTP?"}
//                 </p></center>
//                 <button
//                   type="button"
//                   className="resend-button123556"
//                   onClick={handleResendOtp}
//                   disabled={resendDisabled}
//                 >
//                   Resend OTP
//                 </button>
//               </form>
//             )}
//             {/* Success Message Step 3 */}
//             {step === 3 && (
//               <div className="success-message556">
//                 <img src="https://media.tenor.com/WsmiS-hUZkEAAAAj/verify.gif" height="200px" width="200px" alt="sucess.gif" />
//                 <h3 style={{ color: "green" }}>Registration Successful!</h3>
//                 <button onClick={() => navigate("/login")} className="login-button556">
//                   Proceed to Login
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RegistrationPage;