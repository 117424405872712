import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./dashboard.css";

const Dashboard = () => {
  const [user, setUser] = useState({ name: "", email: "", userType: "" });
  const [profileImage, setProfileImage] = useState(() => {
    return localStorage.getItem("profileImage") || "/images/man1.png";
  });
  const [isEditingImage, setIsEditingImage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("authToken");

        const response = await fetch("https://back.startupconnectz.com/api/users/dashboard", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error("Failed to fetch user data");

        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        alert("Session expired. Please log in again.");
        navigate("/login");
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const imageData = reader.result;
        setProfileImage(imageData);
        localStorage.setItem("profileImage", imageData);
        setIsEditingImage(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleEditImage = () => {
    setIsEditingImage((prevState) => !prevState);
  };

  return (
    <div className="dashboard-containerIILL">
      <aside className="sidebarIILL">
        <h2 className="sidebar-headingIILL">{user.userType || "User"}</h2> {/* Dynamic User Type */}
        <center>
          <div className="profile-containerIILL">
            <div className="profile-img-containerIILL">
              <img
                src={profileImage}
                alt="Profile"
                className="profile-imgIILL"
              />
              <div className="edit-logo-containerIILL">
                {isEditingImage ? (
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    className="image-upload-btnIILL"
                  />
                ) : (
                  <button className="edit-logo-btnIILL" onClick={toggleEditImage}>
                    Edit Logo
                  </button>
                )}
              </div>
            </div>
            <div className="profile-info-containerIILL">
              <p className="profile-nameIILL">{user.name || "Loading..."}</p>
              <p className="profile-emailIILL">{user.email || "Loading..."}</p>
            </div>
          </div>
        </center>
      </aside>
      <main className="main-contentIILL">
        <header className="headerIILL">
          <h1>Welcome to Your Dashboard</h1>
          <p className="welcome-textIILL">
            Manage your account settings, profile, and activities.
          </p>
        </header>
        <section className="contentIILL">
          <div
            className="content-boxIILL"
            onClick={() => navigate("/overview")}
          >
            <h2>Overview</h2>
            <p>Your recent activities and status overview.</p>
          </div>
          <div
            className="content-boxIILL"
            onClick={() => navigate("/settings")}
          >
            <h2>Settings</h2>
            <p>Manage your account settings and preferences.</p>
          </div>
          <div
            className="content-boxIILL"
            onClick={() => navigate("/profile")}
          >
            <h2>Profile</h2>
            <p>View and edit your personal profile information.</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
