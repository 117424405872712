// import React, { useState } from 'react';
// import './RegistrationHome.css';
// import { useNavigate } from 'react-router-dom';

// const roles = [
//   { name: 'Startup', description: 'Already started your entrepreneurial journey as an idea or newly established business.', color: '#f3922b', boxShadow: 'inset 0 0 8px orange' },
//   { name: 'Mentor', description: 'A mentor may share with a mentee (protege) information about...', color: '#64a3ff' },
//   { name: 'Investor', description: 'An investor is a non-professional investor who buys and sells securities.', color: '#ad76ff' },
// ];

// const RegistrationHome = () => {
//   const [selectedRole, setSelectedRole] = useState(null);
//   const navigate = useNavigate();

//   const handleRoleClick = (role) => {
//     setSelectedRole(role.name);
//   };

//   const handleNextClick = async () => {
//     if (!selectedRole) {
//       alert("Please select a role before proceeding.");
//       return;
//     }

//     try {
//       // Send API request to localhost for user type validation
//       const response = await fetch('https://back.startupconnectz.com/api/validate-selection', {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({
//           userType: selectedRole, // Send selected role (user type)
//         }),
//       });

//       const data = await response.json();

//       if (data.success) {
//         // If validation is successful, navigate to the appropriate registration page
//         switch (selectedRole) {
//           case 'Startup':
//             navigate('/StartupRegistration');
//             break;
//           case 'Mentor':
//             navigate('/MentorRegistration');
//             break;
//           case 'Investor':
//             navigate('/InvestorRegistration');
//             break;
//           default:
//             break;
//         }
//       } else {
//         alert(data.message); // Show error message from server if validation fails
//       }
//     } catch (error) {
//       console.error('Error validating selection:', error);
//       alert("An error occurred. Please try again later.");
//     }
//   };

//   return (
//     <div className="registration-container459">
//       <img src="/images/Modern Office with Natural Light.jpeg" alt="Modern Office" />
//       <p className="registration-header459">TELL US WHO YOU ARE! SELECT ONE OF THESE</p>

//       <div className="roles-container459">
//         {roles.map((role) => (
//           <div
//             key={role.name}
//             className={`role-section459 ${selectedRole === role.name ? 'selected459' : ''}`}
//             onClick={() => handleRoleClick(role)}
//             style={{ borderColor: role.color }}
//           >
//             <h3 style={{ color: role.color }}>{role.name}</h3>
//             <p>{role.description}</p>
//             <div className={`radio-button459 ${selectedRole === role.name ? 'checked459' : ''}`}>
//               {selectedRole === role.name && <span className="checkmark459">➔</span>}
//             </div>
//           </div>
//         ))}
//       </div>

//       <button className="next-step-button459" onClick={handleNextClick}>Next Step</button>
//     </div>
//   );
// };

// export default RegistrationHome;
// import React, { useState } from 'react';
// import './RegistrationHome.css';
// import { useNavigate } from 'react-router-dom';

// const roles = [
//   { name: 'Startup', description: 'Already started your entrepreneurial journey as an idea or newly established business.', color: '#f3922b',boxShadow: 'inset 0 0 8px orange' },
//   { name: 'Mentor', description: 'A mentor may share with a mentee (protege) information about...', color: '#64a3ff' },
//   { name: 'Investor', description: 'An  investor is a non-professional investor who buys and sells securities.', color: '#ad76ff' },
// ];

// const RegistrationHome = () => {
//   const [selectedRole, setSelectedRole] = useState(null);
//   const navigate = useNavigate();

//   const handleRoleClick = (role) => {
//     setSelectedRole(role.name);
//   };

//   const handleNextClick = () => {
//     if (!selectedRole) {
//       alert("Please select a role before proceeding.");
//       return;
//     }

//     switch (selectedRole) {
//       case 'Startup':
//         navigate('/StartupRegistration');
//         break;
//       case 'Mentor':
//         navigate('/MentorRegistration');
//         break;
//        case 'Investor':
//          navigate('/InvestorRegistration');
//          break;
//       default:
//         break;
//     }
//   };

//   return (
//     <div className="registration-container459">
//       <img src="/images/Modern Office with Natural Light.jpeg ">
//       </img>
//       <p className="registration-header459">TELL US WHO YOU ARE! SELECT ONE OF THESE</p>

//       <div className="roles-container459">
//         {roles.map((role) => (
//           <div
//             key={role.name}
//             className={`role-section459 ${selectedRole === role.name ? 'selected459' : ''}`}
//             onClick={() => handleRoleClick(role)}
//             style={{ borderColor: role.color }}
//           >
//             <h3 style={{ color: role.color }}>{role.name}</h3>
//             <p>{role.description}</p>
//             <div className={`radio-button459 ${selectedRole === role.name ? 'checked459' : ''}`}>
//               {selectedRole === role.name && <span className="checkmark459">➔</span>}
//             </div>
//           </div>
//         ))}
//       </div>

//       <button className="next-step-button459" onClick={handleNextClick}>Next Step</button>
//     </div>
//   );
// };

// export default RegistrationHome;



// import { useState } from "react";
// import axios from "axios";

// const UserTypeSelection = () => {
//   const [email, setEmail] = useState("");
//   const [userType, setUserType] = useState("");
//   const [message, setMessage] = useState("");
//   const [isSuccess, setIsSuccess] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await axios.post("https://back.startupconnectz.com/api/validate-selection", { email, userType });

//       if (response.data.success) {
//         setIsSuccess(true);
//         setMessage(response.data.message);
//         // Navigate to the next page or handle success
//       } else {
//         setIsSuccess(false);
//         setMessage(response.data.message);
//       }
//     } catch (error) {
//       setIsSuccess(false);
//       setMessage("Error validating user type. Please try again later.");
//     }
//   };

//   return (
//     <div>
//       <h1>User Type Validation</h1>
//       <form onSubmit={handleSubmit}>
//         <input 
//           type="email" 
//           placeholder="Enter your email" 
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//         />
//         <select value={userType} onChange={(e) => setUserType(e.target.value)}>
//           <option value="">Select User Type</option>
//           <option value="Startup">Startup</option>
//           <option value="Investor">Investor</option>
//           <option value="Mentor">Mentor</option>
//         </select>
//         <button type="submit">Validate</button>
//       </form>

//       {message && (
//         <p style={{ color: isSuccess ? "green" : "red" }}>
//           {message}
//         </p>
//       )}
//     </div>
//   );
// };

// export default UserTypeSelection;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import './RegistrationHome.css';

const UserTypeValidation = () => {
  const [userType, setUserType] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if userType is selected
    if (!userType) {
      setIsSuccess(false);
      setMessage("Please select a valid user type.");
      return;
    }

    try {
      // Get the token from localStorage (or sessionStorage if you're using that)
      const token = localStorage.getItem("authToken");

      // If no token is found, prompt to login again (optional)
      if (!token) {
        setIsSuccess(false);
        setMessage("Please log in first.");
        return;
      }

      // Make the API request with userType and the token in the header
      const response = await axios.post("https://back.startupconnectz.com/api/validate-selection", 
        { userType }, 
        {
          headers: {
            Authorization: `Bearer ${token}` // Use correct token format
          }
        }
      );
      
      if (response.data.success) {
        setIsSuccess(true);
        setMessage(response.data.message); // Success message from the API
        navigateToNextPage(userType); // Navigate based on selected user type
      } else {
        setIsSuccess(false);
        setMessage(response.data.message); // Failure message from the API
      }
    } catch (error) {
      setIsSuccess(false);
      console.error(error); // Log error to console for debugging purposes
      setMessage("Error validating user type. Please try again later.");
    }
  };

  // Navigate based on the selected user type
  const navigateToNextPage = (userType) => {
    switch (userType) {
      case "Startup":
        navigate("/StartupRegistration");
        break;
      case "Mentor":
        navigate("/MentorRegistration");
        break;
      case "Investor":
        navigate("/InvestorRegistration");
        break;
      default:
        break;
    }
  };

    // Skip button logic: Redirect to dashboard or another page
    const handleSkip = () => {
      navigate("/home"); // Change to your preferred route
    };

  return (
    
    <div className="user-type-validation-container">
      
      <h1>TELL US WHO YOU ARE! SELECT ONE OF THESE</h1>

      <form className="user-validation-form" onSubmit={handleSubmit}>
        <div className="user-type-options">
          <div
            className={`user-type-option ${userType === "Startup" ? "selected" : ""}`}
            onClick={() => setUserType("Startup")}
          >
            <h3>Startup</h3>
            <p>Build and grow your startup.</p>
          </div>

          <div
            className={`user-type-option ${userType === "Investor" ? "selected" : ""}`}
            onClick={() => setUserType("Investor")}
          >
            <h3>Investor</h3>
            <p>Invest in startups and ideas.</p>
          </div>

          <div
            className={`user-type-option ${userType === "Mentor" ? "selected" : ""}`}
            onClick={() => setUserType("Mentor")}
          >
            <h3>Mentor</h3>
            <p>Guide startups with your expertise.</p>
          </div>
        </div>

        <button type="submit" className="validate-button">Next Step</button>
        {message && (
        <p style={{ color: isSuccess ? "green" : "red" }}>
          {message}
        </p>
        
      )}
      <button type="button" className="skip-button" onClick={handleSkip}>Skip</button>
      </form>

      
    </div>
  );
};

export default UserTypeValidation;
