import React, { useState } from 'react';
import './overview.css';
import { useNavigate } from 'react-router-dom';
import { FaHandPointRight, FaWhatsapp, FaLinkedin, FaCopy, FaTimes } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6'; // Import X logo

const Overview = ({ recentConnections, pendingRequests, profileViews, messages, suggestedConnections }) => {
    const navigate = useNavigate();
    const [showInviteModal, setShowInviteModal] = useState(false);

    const backclick = () => {
        navigate('/dashboard');
    };

    const inviteLink = `https://startupconnectz.com`; // Replace with dynamic user ID if needed

    const handleCopyLink = () => {
        navigator.clipboard.writeText(inviteLink);
        alert("Invite link copied to clipboard!");
    };

    const handleDeleteAccount = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete your account?");
        if (!confirmDelete) return;

        try {
            const token = localStorage.getItem("authToken"); // Fetch correct token key

            if (!token) {
                alert("No token found. Please log in again.");
                navigate("/login"); // Redirect to login page
                return;
            }

            const response = await fetch("https://back.startupconnectz.com/api/users/delete-account", {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });

            const data = await response.json();

            if (response.ok) {
                alert(data.message);
                localStorage.clear(); // Clear all user session data
                navigate("/login"); // Redirect to homepage
            } else {
                alert(data.message || "Failed to delete account. Please try again.");
            }
        } catch (error) {
            console.error("Error deleting account:", error);
            alert("Failed to delete account. Please try again.");
        }
    };

    return (
        <div className="overview-container">
            <h2 className='overview-h2'>Overview</h2>

            <div className="overview-row">
                {/* Activity Summary */}
                <section className="overview-column">
                    <h3 className='overview-h3'>Activity Summary</h3>
                    <p><strong>Recent Connections:</strong> {recentConnections?.length > 0 ? recentConnections.join(", ") : "No recent connections"}</p>
                    <p><strong>Pending Requests:</strong> {pendingRequests || 0}</p>
                    <p><strong>Profile Views:</strong> {profileViews || 0}</p>
                    <p><strong>Messages:</strong> {messages || 0}</p>
                </section>

                {/* Quick Actions */}
                <section className="overview-column">
                    <h3 className='overview-h3'>Quick Actions</h3>
                    <div className='nav-btn-overview'>
                        <button className="overview-btn" onClick={() => navigate('/')}>
                            Connect with New User
                        </button>
                        
                        

                        {/* Invite Others Button - Opens Modal */}
                        <button className='overview-btn' onClick={() => setShowInviteModal(true)}>
                            Invite Others
                        </button>

                        <button className='overview-btn'>Upgrade Plan</button>
                        <button className='overview-btn' onClick={handleDeleteAccount}>Delete Account</button>
                    </div>
                </section>

                {/* Platform Insights */}
                <section className="overview-column">
                    <h3 className='overview-h3'>Platform Insights</h3>
                    <p><strong>Trending Startups:</strong> {suggestedConnections?.length > 0 ? suggestedConnections.join(", ") : "No suggestions available"}</p>
                </section>
            </div>
            {/* Invite Modal (Shows when Button is Clicked) */}
            {showInviteModal && (
                <div className="invite-modal">
                    <div className="invite-modal-content">
                        <FaTimes className="close-icon" onClick={() => setShowInviteModal(false)} />
                        <h3>Share This Platform</h3>
                        <p>Invite your friends and connections:</p>
                        <div className="invite-share">
                            <a href={`https://wa.me/?text=Join%20me%20on%20this%20platform%20${encodeURIComponent(inviteLink)}`} target="_blank" rel="noopener noreferrer">
                                <FaWhatsapp className="invite-icon whatsapp" />
                            </a>
                            <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(inviteLink)}`} target="_blank" rel="noopener noreferrer">
                                <FaLinkedin className="invite-icon linkedin" />
                            </a>
                            <a
                                href={`https://twitter.com/intent/tweet?text=Join%20me%20on%20this%20platform%20${encodeURIComponent(inviteLink)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaXTwitter className="invite-icon x-logo" />
                            </a>

                            <FaCopy className="invite-icon copy" onClick={handleCopyLink} />
                        </div>
                    </div>
                </div>
            )}



            {/* To-Do List / Reminders */}
            <section className="overview-todo">
                <h3 className='overview-h3'>To-Do List / Reminders</h3>
                <ul className="todo-list">
                    <li className="todo-item">
                        <FaHandPointRight className="todo-icon" /> Complete your profile
                    </li>
                    <li className="todo-item">
                        <FaHandPointRight className="todo-icon" /> Respond to {pendingRequests || 0} pending connection requests
                    </li>
                    <li className="todo-item">
                        <FaHandPointRight className="todo-icon" /> Add a new product (if applicable)
                    </li>
                </ul>
            </section>

            <button className='back-btn' onClick={backclick}>Back</button>
        </div >
    );
};

export default Overview;
