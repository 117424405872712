import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();

  // Function to fetch profile data based on the user type
  const fetchProfileData = async () => {
    const userType = localStorage.getItem("userType");
    const token = localStorage.getItem("authToken");

    console.log("User Type:", userType); // Debugging: Log userType

    if (!token) {
      navigate("/login");
      return;
    }

    const endpoint =
  userType === "Startup"
    ? "/api/users/startup-profile"
    : userType === "Mentor"
    ? "/api/users/mentor-profile"
    : userType === "Investor"
    ? "/api/users/investor-profile"
    : null;


    if (!endpoint) {
      console.error("Invalid user type");
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(`https://back.startupconnectz.com${endpoint}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        alert("Session expired. Please log in again.");
        navigate("/login");
        return;
      }

      if (!response.ok) throw new Error("Failed to fetch profile data");

      const data = await response.json();

      // Set profile data based on user type
      // Set profile data based on user type
      if (userType === "Startup") {
        setProfile({
          userType,
          data: {
            user: data.user,
            startup: data.startup, // Set startup-specific data
          },
        });
      } else if (userType === "Mentor") {
        setProfile({
          userType,
          data: {
            user: data.user,
            mentor: data.mentor, // Set mentor-specific data
          },
        });
      } else if (userType === "Investor") {
        setProfile({
          userType,
          data: {
            user: data.user,
            investor: data.investor, // Set investor-specific data
          },
        });
      }

    } catch (error) {
      console.error("Error fetching profile data:", error);
      alert("Session expired. Please log in again.");
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, [navigate]);

  const backclick = () => {
    navigate("/dashboard");
  };

  if (!profile) {
    return <div>Loading...</div>;
  }

  const { userType, data } = profile;
  const commonDetails = {
    createdAt: new Date(data.user?.createdAt || Date.now()).toLocaleDateString(),
    email: data.user?.email || "N/A",
  };

  const renderStartupDetails = () => {

    return (
      <>
        <h3>General Information</h3>
        <p><strong>Startup Name:</strong> {data.startup?.startupName || "N/A"}</p>
        <p><strong>Mobile:</strong> {data.startup?.mobile || "N/A"}</p>
        <p><strong>Funding Type:</strong> {data.startup?.funding || "Unknown"}</p>
        <p><strong>Startup Stage:</strong> {data.startup?.stage || "N/A"}</p>
        <p><strong>website:</strong>{data.startup?.website || "N/A"}</p>
        <p><strong>App Link:</strong>{data.startup?.appLink || "N/A"}</p>

        <h3>Business Information</h3>
        <p><strong>Industry:</strong> {data.startup?.industry || "N/A"}</p>
        <p><strong>Sector:</strong> {data.startup?.sector || "N/A"}</p>
        <p><strong>Entity Type:</strong> {data.startup?.entityNature || "N/A"}</p>
        <p><strong>Udyog Aadhaar:</strong> {data.startup?.udyogAadhaar || "N/A"}</p>


        <h3>Location</h3>
        <p><strong>State:</strong>{data.startup?.state || "N/A"}</p>
        <p><strong>City:</strong>{data.startup?.city || "N/A"}</p>
      </>
    );
  };

  const renderMentorDetails = () => (
    <>
      <h3>General Information</h3>
      <p><strong>Mentor Name:</strong> {data.mentor?.mentorName || "N/A"}</p>
      <p><strong>Mobile:</strong> {data.mentor?.mobile || "N/A"}</p> {/* Mobile */}
      <p><strong>Network:</strong> {data.mentor?.network || "N/A"}</p>
      <p><strong>ActiveMonths:</strong> {data.mentor?.activeMonths || "N/A"}</p>
      <p><strong>websiteLink:</strong> {data.mentor?.websiteLink || "N/A"}</p>
      <p><strong>hubProfileLink:</strong> {data.mentor?.hubProfileLink || "N/A"}</p>


      <h3>Business Information</h3>
      <p><strong>Industry:</strong> {data.mentor?.industry || "N/A"}</p>
      <p><strong>Sectors:</strong> {data.mentor?.sectors || "N/A"}</p>
      <p><strong>Stages:</strong> {data.mentor?.stages || "N/A"}</p>
      <p><strong>StartupName:</strong> {data.mentor?.startupName || "N/A"}</p>

      <h3>Location</h3>
      <p><strong>State:</strong>{data.mentor?.state || "N/A"}</p>
      <p><strong>City:</strong> {data.mentor?.city || "N/A"}</p>

      <h3>Success Story</h3>
      <p><strong>successStories:</strong> {data.mentor?.successStories || "N/A"}</p>
      <p><strong>StartupName:</strong> {data.mentor?.startupName || "N/A"}</p>

    </>
    
  );

  const renderInvestorDetails = () => (
    <>
      <h3>Contact Information</h3>
      <p><strong>Name:</strong> {data.investor?.contactInfo?.name || "N/A"}</p>
      <p><strong>Email:</strong> {data.investor?.contactInfo?.email || "N/A"}</p>
      <p><strong>Phone:</strong> {data.investor?.contactInfo?.phone || "N/A"}</p>
      <p><strong>Location:</strong> {data.investor?.contactInfo?.location || "N/A"}</p>
  
      <h3>Category Information</h3>
      <p><strong>Category:</strong> {data.investor?.category || "N/A"}</p>
  
      <h3>Investment Interest</h3>
      <p><strong>Investment Amount:</strong> {data.investor?.interest?.investmentAmount || "N/A"}</p>
      <p><strong>Focus Areas:</strong> {data.investor?.interest?.focusAreas?.join(", ") || "N/A"}</p>
      <p><strong>Investment Stage:</strong> {data.investor?.interest?.investmentStage || "N/A"}</p>
      <p><strong>Investment Level:</strong> {data.investor?.interest?.investmentLevel || "N/A"}</p>
  
      
    </>
  );
  

  return (
    <div className="profile-container">
      <div className="profile-header-banner">
        <h1>
          Welcome,{" "}
          {userType === "Startup"
            ? data.startup?.startupName || "Startup Founder"
            : userType === "Mentor"
            ? data.mentor?.mentorName || "Mentor"
            : data.investor?.contactInfo?.name || "Investor"}
          !
        </h1>
        <p>Here’s an overview of your profile details.</p>
      </div>
  
      <div className="profile-content">
        <section className="profile-section">
          {userType === "Startup"
            ? renderStartupDetails()
            : userType === "Mentor"
            ? renderMentorDetails()
            : renderInvestorDetails()}
        </section>
  
        <section className="profile-section">
          <h3>Other Details</h3>
          <p><strong>Brief:</strong> {data[userType.toLowerCase()]?.brief || "N/A"}</p>
          <p><strong>Profile Created On:</strong> {commonDetails.createdAt}</p>
        </section>
      </div>
  
      <button className="back-btn" onClick={backclick}>
        Back
      </button>
    </div>
  );
  
};

export default Profile;