import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ForgotPassword.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  // Ensure axios uses credentials for the request
  axios.defaults.withCredentials = true;

  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting email:", email);

    axios.post('https://back.startupconnectz.com/api/users/forgot-password', { email }, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
        .then(res => {
            console.log("Response:", res); // Log the response
            if (res.data.Status === "Success") {
                alert('Password reset link sent to your email.');
                navigate('/login');
            }
        })
        .catch(err => {
            console.error("Error:", err.response ? err.response.data : err); // Log detailed error
            alert('An error occurred. Please try again later.');
        });
};


  return (
    <div className="forgot-password-page-container">
  <div className="forgot-password-form-wrapper">
    <h2 className="forgot-password-title">Forgot Password</h2>
    <form onSubmit={handleSubmit} className="forgot-password-form">
      <div className="forgot-password-input-container">
        <label className="forgot-password-label">Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
          className="forgot-password-input-field"
        />
      </div>
      <button type="submit" className="forgot-password-submit-btn">Submit</button>
    </form>
  </div>
</div>

  );
}

export default ForgotPassword;
