import React, { useState } from 'react';
import './Footer.css';

const Footer = () => {
    // Move useState hooks inside the component
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Send email to backend
        try {
            const response = await fetch("/api/subscribe", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setStatus("Subscribed successfully!");
            } else {
                setStatus("Subscription failed. Try again.");
            }
        } catch (error) {
            setStatus("Error occurred. Try again later.");
        }
    };

    return (
        <footer className="footer123">
            <center>
                <div className="email-subscription">
                    <form onSubmit={handleSubmit} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email to subscribe"
                            required
                            style={{
                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid black",
                                width: "300px",
                                fontSize: "16px",
                            }}
                        />
                        <button
                            type="submit"
                            style={{
                                backgroundColor: "red",
                                color: "white",
                                padding: "10px 20px",
                                borderRadius: "5px",
                                border: "none",
                                fontSize: "16px",
                                cursor: "pointer",
                            }}
                        >
                            Subscribe
                        </button>
                    </form>

                </div>
                {status && <p style={{ marginTop: "-10px", color: "red", textAlign: "center" }}>{status}</p>}
            </center>


            <div className="footer-container-desktop">
                <div className="footer-container">
                    {/* Left Section */}
                    <div className="footer-left">
                        <div className="footer-logo">
                            <img
                                src="/images/Startup-connectz copy.png"
                                alt="Start-up Nexus Logo"
                            />
                        </div>
                        <div className="footer-info">
                            <p>
                                Startup Connect connects entrepreneurs, investors, and experts, fostering collaboration
                                and innovation, offering guidance, funding, and growth opportunities for startups.
                            </p>
                            <div className="social-links">
                                <a href=" https://www.facebook.com/profile.php?id=61573142457650" target="_blank" rel="noopener noreferrer">
                                    <img src="https://cdn-icons-png.flaticon.com/128/3665/3665167.png" alt="facebook" />
                                </a>
                                <a href="https://www.linkedin.com/in/startup-connect" target="_blank" rel="noopener noreferrer">
                                    <img src="https://cdn-icons-png.flaticon.com/128/4494/4494497.png" alt="linkedin" />
                                </a>
                                <a href="https://www.instagram.com/startupconnect_2025" target="_blank" rel="noopener noreferrer">
                                    <img src="https://cdn-icons-png.flaticon.com/128/15707/15707749.png" alt="instagram" />
                                </a>
                                <a href="https://www.youtube.com/@startup_connect" target="_blank" rel="noopener noreferrer">
                                    <img src="https://cdn-icons-png.flaticon.com/128/3665/3665173.png" alt="youtube" />
                                </a>
                                <a href=" https://x.com/Start_upconnect" target="_blank" rel="noopener noreferrer">
                                    <img src="https://cdn-icons-png.flaticon.com/128/5969/5969020.png" alt="twitter" />
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Middle Section */}
                    <div className="footer-middle2">
                        <h2>Quick Links</h2>
                        <ul>
                            <li>
                                <a href="/Faq12">
                                    <span className="arrow-icon">&gt;</span> FAQ
                                </a>
                            </li>
                            <li>
                                <a href="/startup">
                                    <span className="arrow-icon">&gt;</span> Startup
                                </a>
                            </li>
                            <li>
                                <a href="/Mentorlist">
                                    <span className="arrow-icon">&gt;</span> Mentors
                                </a>
                            </li>
                            <li>
                                <a href="/InvestorCont1">
                                    <span className="arrow-icon">&gt;</span> Investors
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="footer-middle1">
                        <ul>
                            <li>
                                <a href="/navmenu5">
                                    <span className="arrow-icon">&gt;</span> Recognition
                                </a>
                            </li>
                            <li>
                                <a href="/contactus">
                                    <span className="arrow-icon">&gt;</span> Contact Us
                                </a>
                            </li>
                            <li>
                                <a href="/privacypolicy">
                                    <span className="arrow-icon">&gt;</span> Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a href="/termsandcondition">
                                    <span className="arrow-icon">&gt;</span> Terms and Condition
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Right Section */}
                    <div className="footer-right">
                        <h2>Contact Us</h2>
                        <div className="contact-item">
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/561/561127.png"
                                alt="Email Icon"
                                className="contact-icon"
                            />
                            <a href="mailto:support@startupconnect.com">support@startupconnect.com</a>
                        </div>
                        <div className="contact-item">
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/684/684908.png"
                                alt="Address Icon"
                                className="contact-iconn"
                            />
                            <p>NO: 4, 2nd St Rd, Thendral Nagar, Sathuvachari, Vellore, Tamil Nadu 632012.</p>
                        </div>
                        <div className="contact-item">
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/724/724664.png"
                                alt="Phone Icon"
                                className="contact-icon"
                            />
                            <a>+91 7092774077</a>
                        </div>
                    </div>
                </div>
                <hr className='hrfooter66'></hr>
                <p className="footer-copyright">
                    Copyright © 2024 Startup-Connect. All Rights Reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
