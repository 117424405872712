import './Dpiit2.css';
import { Fade } from 'react-awesome-reveal';
import { FaQuestionCircle } from "react-icons/fa";
// import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";



const Dpiit2= () => {
  const contentVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1, transition: { duration: 1 } },
    hover: { scale: 1.05, transition: { duration: 0.3 } },
  };
  const handleYouTubeRedirect = () => {
    window.open("https://www.youtube.com/channel/your-channel-id", "_blank");
  };
  

  const navigate = useNavigate();
  const handleDpiit1 = () =>{
    navigate('/dpiit1');
  }
    const handleNavigation = () => {
      navigate("/Dpiitfaq"); // Replace with your target page route
    };


  return (
    <div className="N009dpiit-endorsement-container">
      <header className="dpiit-header-container-ZZ1">
        <motion.div
          className="dpiit-header-ZZ1"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <motion.h1
            className="header-title-ZZ1"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            Student Recognition
          </motion.h1>
          <motion.p
            className="header-subtitle-ZZ1"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
         The Student Recognition Program acknowledges students who meet specific eligibility criteria, helping them gain access to various benefits. Recognized students may receive academic support, funding opportunities, mentorship programs, and simplified processes for scholarships and internships. This recognition serves as a stepping stone for students to enhance their career prospects and academic growth.
          </motion.p>
        </motion.div>
      </header><br></br>
      <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }}>
      <section className="N009header-section">
        <motion.div
          className="N009header-content"
          variants={contentVariants}
        >
          {/* <h1 className="N009header-title">Recognition</h1> */}
          <p className="N009header-description">
          Gain access to exclusive benefits, including financial assistance for academic projects, simplified application processes for scholarships and grants, and priority access to mentorship and internship opportunities. These perks are designed to support your journey, enhance your skills, and open doors to greater career prospects.
          </p>
          <motion.button
            className="N009get-recognised-btn"
            whileHover={{ scale: 1.1, boxShadow: "0 5px 15px rgba(0, 0, 0, 0.2)" }}
            whileTap={{ scale: 0.95 }}
            onClick={handleDpiit1}
          >
            Get Recognised
          </motion.button>
        </motion.div>

        <motion.div
  className="N009header-image-container"
  variants={imageVariants}
  whileHover="hover"
>
  <img
    src="\images\rr1.png"
    alt="Startup Growth"
    className="N009header-image"
  />
</motion.div>

      </section>
    </motion.div>

      <Fade direction="up" triggerOnce>
  <section className="N009criteria-section">
    <h2>CRITERIA FOR STUDENT STARTUP</h2><br></br>
    <div className="N009criteria-grid">
      <div className="N009criteria-item">
        <div className="N009criteria-header">
        <img src="\images\dd2.png" alt="Company Age Icon" className="N009circle-logo" />
          <h3>Student-Led Initiative</h3>
        
        </div>
        <p>The startup should be founded or co-founded by a student or recent graduate (within the last 3 years).</p>
      </div>
      <div className="N009criteria-item">
        <div className="N009criteria-header">
        <img src="\images\dd1.png" alt="Company Formation Icon" className="N009circle-logo" />
          <h3>Innovation & Scalability</h3>
        </div>
        <p>The startup should focus on innovation, technology-driven solutions, or a unique business model.</p>
      </div>
      <div className="N009criteria-item">
        <div className="N009criteria-header">
        <img src="\images\dd5.png" alt="Revenue Requirement Icon" className="N009circle-logo" />
          <h3>Legal Entity Formation</h3>
        </div>
        <p>The startup should be registered as a private limited company, LLP, or partnership.
        </p>
      </div>
      <div className="N009criteria-item">
        <div className="N009criteria-header">
        <img src="\images\dd3.png" alt="Unique Entity Icon" className="N009circle-logo" />
          <h3>Revenue & Investment</h3>
        </div>
        <p>The startup should not have an annual turnover exceeding a specified limit (e.g., ₹25 crore) in any financial year since incorporation.</p>
      </div>
      <div className="N009criteria-item">
        <div className="N009criteria-header">
        <img src="\images\dd4.png" alt="Creativity Icon" className="N009circle-logo" />
          <h3>Creativity & Scalability</h3>
        </div>
        <p>The startup must aim to enhance products or services and have scalable business models.</p>
      </div>
    </div>
  </section>
</Fade>
<section className="dpiit-benefits-ZZ1">
        <motion.h2
          className="section-title-ZZ1"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Key Benefits of Student Recognition
        </motion.h2>
        <ul className="benefits-list-ZZ1">
          {[
            "Access to tax exemptions for three consecutive years.",
            "Priority in government tenders and procurement.",
            "Fast-tracking of intellectual property applications.",
            "Eligibility for Fund of Funds to boost financing.",
            "Relaxed norms for public procurement and compliance.",
            "Opportunities to network with investors and mentors.",
          ].map((benefit, index) => (
            <motion.li
              key={index}
              className="benefit-item-ZZ1"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              {benefit}
            </motion.li>
          ))}
        </ul>
      </section>
<div className="N009recognition-section-container">
      {/* Left-side Content */}
      <div className="N009recognition-section-left">
        <h2 className="N009recognition-title">
          At Startup Connect, we recognize the relentless efforts of entrepreneurs, mentors, investors, and organizations that contribute to the growth of the startup ecosystem.
        </h2>
        <p className="N009recognition-text">
          Our recognition platform highlights the success stories, achievements, and innovations that shape the future of startups. Join us in celebrating the impact of these individuals and organizations in the startup world.
        </p>
        <a href="/recognition" className="N009recognition-link">
          <strong>Learn more</strong>
        </a>
      </div>

      <div className="N009recognition-section-right">
      <motion.div
        className="N009image-container"
        variants={imageVariants}
        initial="hidden"
        animate="visible"
        whileHover="hover"
        onClick={handleYouTubeRedirect}
        style={{ cursor: "pointer" }}
      >
        <img
          src="\images\Cap.PNG" // Replace with your image path
          alt="Visit YouTube Channel"
          className="N009image"
          style={{ width: "100%", height: "200px", objectFit: "cover", borderRadius: "8px" }}
        />
        <div className="N009image-link">
          <p style={{ textAlign: "center", marginTop: "10px", fontWeight: "bold" }}>
            Visit our YouTube Channel!
          </p>
        </div>
      </motion.div>
    </div>
    </div>
    <div className="sticky-faq-container" onClick={handleNavigation}>
      <div className="faq-popup">
        <FaQuestionCircle size={50} />
        <p>FAQ</p>
      </div>
    </div>
  
        <Fade direction="up" triggerOnce>
  <section className="N009useful-links-section">
    <div className="N009logo-container">
      <img src="\images\dd6.png" alt="Logo" className="N009section-logo" />
      <h2>Useful Links</h2>
    </div>
    <div className="N009links-grid">
      {/* Policy Notifications PDF link */}
      <div className="N009link-item">
        <img src="\images\dd7.png" alt="Policy Notifications" className="N009item-logo" />
        <a 
          href="D:\startup\myapp\myapp\public\assets.pdf" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Policy Notifications
        </a>
      </div>

      {/* Income Tax Notifications Page Link */}
      <div className="N009link-item">
        <img src="\images\dd8.png" alt="Income Tax Notifications" className="N009item-logo" />
        <a 
          href="https://example.com/income-tax-notifications" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Income Tax Notifications
        </a>
      </div>

      {/* Recognised & Tax Certificates Page Link */}
      <div className="N009link-item">
        <img src="\images\dd9.png" alt="Recognised & Tax Certificates" className="N009item-logo" />
        <a 
          href="https://example.com/recognised-tax-certificates" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Recognised & Tax Certificates
        </a>
      </div>

      {/* Startup India Action Plan PDF link */}
      <div className="N009link-item">
        <img src="\images\dd10.png" alt="Startup India Action Plan" className="N009item-logo" />
        <a 
          href="/path-to-pdf/StartupIndiaActionPlan.pdf" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Startup Connect Action Plan
        </a>
      </div>

      {/* List of Facilitators for Patent Page Link */}
      <div className="N009link-item">
        <img src="\images\dd11.png" alt="List of Facilitators for Patent" className="N009item-logo" />
        <a 
          href="https://example.com/list-of-facilitators-patent" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          List of Facilitators for Patent
        </a>
      </div>

      {/* List of Facilitators for TradeMarks Page Link */}
      <div className="N009link-item">
        <img src="\images\dd12.png" alt="List of Facilitators for TradeMarks" className="N009item-logo" />
        <a 
          href="https://example.com/list-of-facilitators-trademarks" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          List of Facilitators for TradeMarks
        </a>
      </div>

      {/* FAQs on Patents Page Link */}
      <div className="N009link-item">
        <img src="\images\dd13.png" alt="FAQs on Patents" className="N009item-logo" />
        <a 
          href="https://example.com/faqs-on-patents" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          FAQs on Patents
        </a>
      </div>

      {/* GeM Startup Runway Page Link */}
      <div className="N009link-item">
        <img src="\images\dd14.png" alt="GeM Startup Runway" className="N009item-logo" />
        <a 
          href="https://example.com/gem-startup-runway" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          GeM Startup Runway
        </a>
      </div>
    </div>
  </section>
</Fade>

    </div>
  );
};

export default Dpiit2;
