// import React, { useState } from 'react';
// import './InvestorConnect.css';
// import { FaMapMarkerAlt } from 'react-icons/fa';
// import { FaLevelUpAlt } from 'react-icons/fa';
// import { Link } from 'react-router-dom';

// const InvestorConnect = () => {
//   const investorsData = [
//     {
//       name: "Robert Andrew",
//       location: "Bangalore, India",
//       phone: "+91 98789 98767",
//       email: "robert345@gmail.com",
//       value: "Investment Range: 5L to 6L",
//       investorLevel: "Intermediate"
//     },
//     {
//       name: "Alice Bennett",
//       location: "New York, USA",
//       phone: "+1 234 567 8901",
//       email: "alice123@gmail.com",
//       value: "Investment Range: 2L to 3L",
//       investorLevel: "Beginner"
//     },
//     {
//       name: "Chris Douglas",
//       location: "London, UK",
//       phone: "+44 1234 567890",
//       email: "chris123@gmail.com",
//       value: "Investment Range: 7L to 8L",
//       investorLevel: "Expert"
//     },
//     {
//       name: "Emma Watson",
//       location: "Bangalore, India",
//       phone: "+91 98789 98767",
//       email: "emma.w@gmail.com",
//       value: "Investment Range: 2L to 3L",
//       investorLevel: "Beginner"
//     },
//     {
//       name: "Michael Johnson",
//       location: "California, USA",
//       phone: "+1 345 678 9012",
//       email: "michael.j@gmail.com",
//       value: "Investment Range: 50L to 1L",
//       investorLevel: "Expert"
//     },
//     {
//       name: "Johnson",
//       location: "Tokyo, USA",
//       phone: "+1 345 678 9012",
//       email: "johnson@gmail.com",
//       value: "Investment Range: 1L to 4L",
//       investorLevel: "Others"
//     }
//   ];


//   const citiesByState = {
//     AndhraPradesh: [" Visakhapatnam", "Vijayawada", "Tirupati", "Guntur", "Rajahmundry"],
//     ArunachalPradesh: ["Itanagar", "Tawang", "Pasighat", "Ziro"],
//     Assam: ["Guwahati", "Dibrugarh", "Jorhat", "Silchar"],
//     Bihar: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur"],
//     Chhatisgarh: ["Raipur", "Bilaspur", "Bhilai", "Korba"],
//     Goa: ["Panaji", "Margao", "Vasco da Gama", "Mapusa"],
//     Gujarat: [" Ahmedabad", "Surat", "Vadodara", "Rajkot"],
//     Haryana: ["Gurgaon", "Faridabad", "Panipat", "Ambala"],
//     HimachalPradesh: ["Shimla", "Manali", "Dharamshala", "Solan"],
//     Jharkhand: ["Ranchi", "Jamshedpur", "Dhanbad", "Bokaro"],
//     Karnataka: ["Bangalore", "Mysore", "Mangalore"],
//     Kerala: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Thrissur"],
//     MadhyaPradesh: ["Bhopal", "Indore", "Gwalior", "Jabalpur"],
//     Maharashtra: [" Mumbai", "Pune", "Nagpur", " Nashik"],
//     Manipur: ["Imphal", "Churachandpur", "Thoubal", "Moirang"],
//     Meghalaya: ["Shillong", "Tura", " Nongstoin", "Jowai"],
//     Mizoram: ["Aizawl", "Lunglei", "Champhai", " Serchhip"],
//     Nagaland: ["Kohima", "Dimapur", "Mokokchung", " Wokha"],
//     Odisha: ["Bhubaneswar", "Cuttack", "Rourkela", " Puri"],
//     Punjab: ["Chandigarh", "Amritsar", "Ludhiana", "Jalandhar"],
//     Rajasthan: [" Jaipur", "Udaipur", "Jodhpur", "Ajmer"],
//     Sikkim: ["Gangtok", "Namchi", "Mangan", "Gyalshing"],
//     TamilNadu: ["Chennai", "Coimbatore", "Madurai", "Trichy"],
//     Telangana: ["Hyderabad", "Warangal", " Nizamabad", " Karimnagar"],
//     Tripura: ["Agartala", "Udaipur", "Dharmanagar", "Kailashahar"],
//     UttarPradesh: [" Lucknow", "Kanpur", "Varanasi", "Agra"],
//     Uttarakhand: ["Dehradun", "Haridwar", " Nainital", " Roorkee"],
//     WestBengal: ["Kolkata", "Howrah", "Siliguri", "Durgapur"],


//   };


//   const [investors, setInvestors] = useState(investorsData);
//   const [searchQuery, setSearchQuery] = useState(""); // New state for search


//   // Sorting method
//   const sortInvestors = (sortBy) => {
//     let sortedInvestors = [...investorsData];
//     if (sortBy === 'name') {
//       sortedInvestors.sort((a, b) => a.name.localeCompare(b.name));
//     } else if (sortBy === 'range') {
//       sortedInvestors.sort((a, b) => {
//         const rangeA = a.value.match(/(\d+)(L?)/)[0];
//         const rangeB = b.value.match(/(\d+)(L?)/)[0];
//         return parseInt(rangeA) - parseInt(rangeB);
//       });
//     }
//     setInvestors(sortedInvestors);
//   };

//   // Handle sort by dropdown change
//   const handleSortChange = (e) => {
//     const selectedSort = e.target.value;
//     setSortBy(selectedSort);
//     sortInvestors(selectedSort);
//   };

//   // Filter investors based on experience level
//   const filterInvestorsByExperience = (experienceLevel) => {
//     if (!experienceLevel) {
//       setInvestors(investorsData);
//       return;
//     }
//     const filtered = investorsData.filter((investor) => investor.experience === experienceLevel);
//     setInvestors(filtered);
//   };

//   // Handle experience level change
//   const handleExperienceLevelChange = (e) => {
//     const selectedExperienceLevel = e.target.value;
//     setExperienceLevel(selectedExperienceLevel); // Update the state
//     filterInvestorsByExperienceLevel(selectedExperienceLevel); // Apply the filter
//   };

//   // Filter investors based on selected experience level
//   const filterInvestorsByExperienceLevel = (experienceLevel) => {
//     const filtered = investorsData.filter((investor) => {
//       return experienceLevel ? investor.investorLevel.toLowerCase() === experienceLevel : true;
//     });
//     setInvestors(filtered); // Update the state with filtered data
//   };



//   // Filter investors based on selected state and city
//   const filterInvestors = () => {
//     const filtered = investorsData.filter((investor) => {
//       const matchesState = state ? investor.state === state : true;
//       const matchesCity = city ? investor.city === city : true;
//       return matchesState && matchesCity;
//     });
//     setInvestors(filtered);
//   };

//   // Handle state change
//   const handleStateChange = (e) => {
//     const selectedState = e.target.value;
//     setState(selectedState);
//     setCity(''); // Reset city when state changes
//     filterInvestors(); // Apply filter based on selected state
//   };

//   // Handle city change
//   const handleCityChange = (e) => {
//     const selectedCity = e.target.value;
//     setCity(selectedCity);
//     filterInvestors(); // Apply filter based on selected city
//   };
//   // Handle search query change
//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };
//   // State for filter selections
//   const [sortBy, setSortBy] = useState('');
//   const [experienceLevel, setExperienceLevel] = useState('');
//   const [state, setState] = useState('');
//   const [city, setCity] = useState('');

//   // Filter investors based on search query
//   const filteredInvestors = investors.filter((investor) =>
//     investor.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     investor.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
//     investor.email.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   return (
//     <>
//       <div className="app4">

//         {/* Top Section - Banner */}
//         <div className="start-img-container">
//           <button className="custom-button">
//             Investor
//           </button>
//         </div>

//         <div className="main-container4">
//           {/* Sidebar */}
//           <div className="sidebar4">
//             <div className="investor-count-card4">
//               <h2>No. of Investors</h2>
//               <h2>76+</h2>
//             </div>
//             <div className="invest-4">
//               <Link to="/startup" style={{ textDecoration: "none", color: 'white' }}>
//                 <h3 className='investment4'>For Your Investment</h3>
//               </Link>
//             </div>
//             <div className="investor-info4">
//               <h2>Why do investors invest in startups?</h2>
//               <p> Investors are drawn to startups for several reasons, primarily the potential for high returns and rapid growth. Startups often operate in emerging markets or introduce disruptive innovations, offering early investors a chance to profit significantly if the company scales or gets acquired. Additionally, many investors look to diversify their portfolios by backing startups, which can offer exposure to industries or technologies not available through traditional investments. Beyond financial motivations, some investors are passionate about entrepreneurship and want to support founders who are solving meaningful problems or driving societal change. Strategic investors may also invest to align with their own business goals or gain access to new technologies. Despite the high risk of failure, the potential for outsized rewards, combined with opportunities for networking, influence, and impact, makes startups an attractive investment choice for those willing to take the gamble.</p>
//             </div>
//           </div>

//           {/* Content Section */}
//           <div className="content-container4">
//             {/* Search and Sort Section */}


//             <div className="filters-container85">
//               <select
//                 className="filter85"
//                 value={sortBy}
//                 onChange={handleSortChange}
//               >
//                 <option value="">Sort By</option>
//                 <option value="name">Name</option>
//                 <option value="range">Range</option>
//               </select>

//               <select
//                 className="filter85"
//                 value={experienceLevel}
//                 onChange={handleExperienceLevelChange}
//               >
//                 <option value="">Investor Level</option>
//                 <option value="beginner">Beginner</option>
//                 <option value="intermediate">Intermediate</option>
//                 <option value="expert">Expert</option>
//                 <option value="expert">Others</option>
//               </select>







//               <select
//                 className="filter85"
//                 value={state}
//                 onChange={handleStateChange}
//               >
//                 <option value="">State</option>
//                 {Object.keys(citiesByState).map((stateOption) => (
//                   <option key={stateOption} value={stateOption}>
//                     {stateOption}
//                   </option>
//                 ))}
//               </select>

//               <select
//                 className="filter85"
//                 value={city}
//                 onChange={handleCityChange}
//                 disabled={!state} // Disable city dropdown if no state is selected
//               >
//                 <option value="">City</option>
//                 {state &&
//                   citiesByState[state].map((cityOption) => (
//                     <option key={cityOption} value={cityOption}>
//                       {cityOption}
//                     </option>
//                   ))}
//               </select>
//             </div>

//             <div className="search-bar4">
//               <input
//                 type="text"
//                 placeholder="Search"
//                 value={searchQuery}
//                 onChange={handleSearchChange} // Bind to search input
//               />
//             </div>



//             {/* Investor List */}
//             <div className="investor-list34">
//               {filteredInvestors.map((investor, index) => (
//                 <Link to="/Investorprofile" style={{ textDecoration: 'none' }} className="investor-card34" key={index}>
//                   <div className="investor-card-content34">
//                     <div className="avatar34">
//                       <img src="/images/avator.png" alt="avatar" />
//                     </div>
//                     <div className="investor-details34">
//                       <h3 className="kar1">{investor.name}</h3>
//                       <p className='investment-range'>{investor.value}</p> {/* Display investment range */}
//                       <p className="investment-levell">
//                         <FaLevelUpAlt style={{ marginRight: '5px', color: '#FFD700', fontSize: '16px' }} />
//                         {investor.investorLevel}
//                       </p>
//                       <p className="kar2">
//                         <FaMapMarkerAlt style={{ marginRight: '5px', color: '#FF0000', paddingTop: '2px' }} />
//                         {investor.location}
//                       </p>


//                       <hr className="investor-card-line34" />
//                       <button className="view-profile-btn34">View Profile</button>
//                     </div>
//                   </div>
//                 </Link>
//               ))}
//             </div>

//             {/* Load More Button */}
//             <div className="load-more-container4">
//               <button className="load-more-btn43">LOAD MORE</button>
//             </div>
//           </div>
//         </div>
//       </div >


//     </>
//   );
// };

// export default InvestorConnect;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './InvestorConnect.css';  // Assuming you have this CSS file for styles
import { FaMapMarkerAlt, FaLevelUpAlt } from 'react-icons/fa';

const InvestorConnect = () => {
  const [investors, setInvestors] = useState([]);
  const [loading, setLoading] = useState(false);

  // Fetch investors from the backend
  useEffect(() => {
    const fetchInvestors = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://back.startupconnectz.com/api/investors/investors'); // API endpoint
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json();
        setInvestors(data.investors);  // Assuming the response has a 'investors' key
      } catch (error) {
        console.error('Error fetching investors:', error.message);
      }
      setLoading(false);
    };

    fetchInvestors();
  }, []);

  return (
    <div className="app4">
      <div className="start-img-container">
        <button className="custom-button">Investors</button>
      </div>

      <div className="main-container4">
        <div className="sidebar4">
          <div className="investor-count-card4">
            <h2>Total Investors</h2>
            <h2>9+</h2>
          </div>
          <div className="invest-4">
            <Link to="/startup" style={{ textDecoration: 'none', color: 'white' }}>
              <h3 className="investment4">For Your Investment</h3>
            </Link>
          </div>
        </div>

        <div className="content-container4">
          <div className="investor-list34">
            {loading ? (
              <p>Loading...</p>
            ) : investors.length > 0 ? (
              investors.map((investor, index) => (
                <Link
                  to={`/investor/${investor._id}`}
                  style={{ textDecoration: 'none', color: 'white' }}
                  className="investor-card34"
                  key={index}
                >
                  <div className="investor-info34">
                    {/* Display Profile Picture */}
                    {investor.profilePicture && (
                      <img
                        src={`https://back.startupconnectz.com/${investor.profilePicture}`}
                        alt={`${investor.contactInfo.name} profile`}
                        className="investor-profile-picture"
                      />
                    )}
                    <h3>{investor.contactInfo.name}</h3>
                    <h3 style={{ color: 'red' }}>{investor.category}</h3>
                    <p><FaLevelUpAlt /> {investor.interest.investmentAmount}</p>
                    <p><FaMapMarkerAlt /> {investor.contactInfo.location}</p>
                  </div>
                </Link>
              ))
            ) : (
              <p>New Investor Registration.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorConnect;