import React, { useState } from "react";
import "./LoginPage.css";
import { Link, useNavigate } from "react-router-dom";

const LoginPage = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // Store error messages
  const navigate = useNavigate(); // Hook to navigate after successful login

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = { email, password };

    try {
      const response = await fetch("https://back.startupconnectz.com/api/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      console.log(`Login response data:`, data);

      if (response.ok) {
        // Save necessary user data to localStorage
        localStorage.setItem("email", data.email);
        localStorage.setItem("authToken", data.token);
        localStorage.setItem("userType", data.userType);  // This will be "Startup" or "Mentor"
        setIsLoggedIn(true);  // Set login status to true

        // Check if the user has already visited the RegistrationHome page during the session
        const hasVisitedRegistrationHome = sessionStorage.getItem("hasVisitedRegistrationHome");

        if (!hasVisitedRegistrationHome) {
          // If the user hasn't visited the page during this session, redirect to RegistrationHome
          sessionStorage.setItem("hasVisitedRegistrationHome", "true"); // Mark as visited for this session
          navigate("/RegistrationHome"); // Redirect to RegistrationHome
        } else {
          // If the user has already visited during the session, redirect to home page
          navigate("/home"); // Redirect to the home page
        }
      } else {
        setErrorMessage(data.message || "Login failed! Please check your credentials.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrorMessage("An error occurred. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="left-panel">
        <h2>#StartupConnect</h2>
        <p>
          StartupConnect connects entrepreneurs, investors, and mentors, promoting networking and collaboration. Entrepreneurs showcase their startups, while investors find opportunities. Mentorship fosters innovation, guiding startups toward growth, success, and mutual ecosystem development.
        </p>
        <div className="connect-logo">
          <img src="/images/chartImage-removebg-preview.png" alt="Connect to Startup" />
          <p>Feel Free To Connect</p>
        </div>
        <h2>Why Join Startup Connect?</h2>
        <p>
          StartupConnect is a platform that bridges entrepreneurs, investors, and mentors, facilitating networking, collaboration, and growth. It helps startups showcase their potential, while investors discover opportunities and support innovation.
        </p>
      </div>
      <div class="center-line"></div>
      <div className="right-panel">
        <h1>Welcome to StartupConnect</h1>
        <form onSubmit={handleSubmit} className="login-form">
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <div className="form-group">
            <label htmlFor="email">Email Id/ User Id</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <a href="/forgetpassword1" className="forgot-password">
              Forgot Password?
            </a>
          </div>


          <div className="form-group1">
            <input className="box" type="checkbox" required />
            <label>
              I agree to the <a href="/termsandcondition">Terms of Service</a> and{" "}
              <a href="/privacypolicy">Privacy Policy</a>
            </label>
          </div>

          <button type="submit" className="login-button">
            LOG IN
          </button>

          <div className="register-section">
            <p>
              Don't have an account?<Link to="/register">Register Now</Link>
            </p>
          </div>
        </form>

        <div className="social-media-icons">
          <a href=" https://www.facebook.com/profile.php?id=61573142457650" target="_blank" rel="noopener noreferrer">
            <img src="https://cdn-icons-png.flaticon.com/128/3665/3665167.png" alt="facebook" />
          </a>
          <a href="https://www.linkedin.com/in/startup-connect" target="_blank" rel="noopener noreferrer">
            <img src="https://cdn-icons-png.flaticon.com/128/4494/4494497.png" alt="linkedin" />
          </a>
          <a href="https://www.instagram.com/startupconnect_2025" target="_blank" rel="noopener noreferrer">
            <img src="https://cdn-icons-png.flaticon.com/128/15707/15707749.png" alt="instagram" />
          </a>
          <a href="https://www.youtube.com/@startup_connect" target="_blank" rel="noopener noreferrer">
            <img src="https://cdn-icons-png.flaticon.com/128/3665/3665173.png" alt="youtube" />
          </a>
          <a href=" https://x.com/Start_upconnect" target="_blank" rel="noopener noreferrer">
            <img src="https://cdn-icons-png.flaticon.com/128/5969/5969020.png" alt="twitter" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
