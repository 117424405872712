import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import './Mentorlist.css';

const Mentorlist = () => {
  
  const [mentors, setMentors] = useState([]);
  const token = localStorage.getItem("authToken"); // Get the token from localStorage

  useEffect(() => {
    const fetchMentors = async () => {
      try {
        const response = await fetch('https://back.startupconnectz.com/api/users/mentorlist', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`, // Add token to the Authorization header
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch mentors');
        }
        const data = await response.json();
        setMentors(data);
      } catch (error) {
        console.error('Error fetching mentors:', error);
      }
    };

    if (token) {  // Ensure the token exists before making the request
      fetchMentors();
    } else {
      console.log("No token found. Please log in.");
    }
  }, [token]);
  

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const emptyStars = 5 - fullStars;

    return (
      <div className="rating85">
        {Array(fullStars).fill().map((_, i) => (
          <FontAwesomeIcon key={`full-${i}`} icon={faStarSolid} className="full-star85" />
        ))}
        {Array(emptyStars).fill().map((_, i) => (
          <FontAwesomeIcon key={`empty-${i}`} icon={faStarRegular} className="empty-star85" />
        ))}
      </div>
    );
  };

  return (
    <div className="mentorlist-container85">
      <div className="start-img-container">
        <button className="custom-button">Mentors</button>
      </div>

      <div className="content-container85">
        <div className="profile-container85">
          {mentors.length > 0 ? (
            mentors.map((mentor) => (
              <div className="profile85" key={mentor._id}>
                {/* Pass mentor's ID in the Link */}
                <Link to={`/MentorProfile/${mentor._id}`} style={{ textDecoration: 'none', color: 'white' }}>
                  <div className="image-container85">
                  <img src={`https://back.startupconnectz.com/${mentor.file}`} 
                  alt={`${mentor.mentorName}'s profile`} className="profile-image85" />
                  </div>  
                  <div className="profile-details85">
                    <h3 className="profile-name85">{mentor.mentorName}</h3>
                    <p className="profile-industry-experience85">Industry: {mentor.industry}</p>
                    <p className="profile-state85">State: {mentor.state}</p>
                    <p className="profile-city85">City: {mentor.city}</p>
                    <p className="profile-months85">Active Months: {mentor.activeMonths}</p>
                    <div className="profile-rating85">{renderStars(Math.floor(Math.random() * 5) + 1)}</div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <p>New Mentor Registration.</p>
          )}
        </div>

        <div className="ads-section85">
          <h3>Sponsored Ads</h3>
          <div className="ad-box85">Google Ad 1</div>
          <div className="ad-box85">Google Ad 2</div>
        </div>
      </div>
    </div>
  );
};

export default Mentorlist;
