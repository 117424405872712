import React, { useState } from "react";
import { FaUser, FaUniversity, FaBuilding, FaPhone, FaEnvelope, FaRocket, FaUsers } from "react-icons/fa";
import "./StartupProfileForm.css";
import { useNavigate } from "react-router-dom";


const StartupProfileForm = () => {
  const navigate = useNavigate(); 
  const [formData, setFormData] = useState({
    studentName: "",
    collegeName: "",
    department: "",
    mobileNumber: "",
    emailId: "",
    startupDomain: "",
    startupSize: "Individual",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://back.startupconnectz.com/api/startup/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Profile submitted successfully!");
        setFormData({
          studentName: "",
          collegeName: "",
          department: "",
          mobileNumber: "",
          emailId: "",
          startupDomain: "",
          startupSize: "Individual",
        });

        navigate("/home"); // Redirect to home page after submission
      } else {
        alert(data.message || "Failed to submit profile");
      }
    } catch (error) {
      console.error("Error submitting profile:", error);
      alert("Error submitting profile");
    }
  };

  return (
    <div className="small-form-containerD33">
      <h3>🚀 Startup Profile</h3>
      <form onSubmit={handleSubmit}>
        {[
          { name: "studentName", label: "Student Name", icon: <FaUser /> },
          { name: "collegeName", label: "College Name", icon: <FaUniversity /> },
          { name: "department", label: "Department", icon: <FaBuilding /> },
          { name: "mobileNumber", label: "Mobile Number", icon: <FaPhone />, type: "tel" },
          { name: "emailId", label: "Email ID", icon: <FaEnvelope />, type: "email" },
          { name: "startupDomain", label: "Startup Domain", icon: <FaRocket /> }
        ].map(({ name, label, icon, type = "text" }) => (
          <div className="small-form-groupD33" key={name}>
            <label>{icon} {label}</label>
            <input 
              type={type} 
              name={name} 
              value={formData[name]} 
              onChange={handleChange} 
              required 
            />
          </div>
        ))}

        <div className="small-form-groupD33">
          <label><FaUsers /> Startup Size</label>
          <select name="startupSize" value={formData.startupSize} onChange={handleChange}>
            <option value="Individual">Individual</option>
            <option value="Team">Team</option>
          </select>
        </div>

        <button type="submit" className="small-submit-btnD33">Register</button>
      </form>
    </div>
  );
};

export default StartupProfileForm;