// import React, { useState } from 'react';
// import './InvestorProfile.css'; // Import the CSS file for styling

// const InvestorProfile = () => {
//   const [hovered, setHovered] = useState(false);
//   const [status, setStatus] = useState('connect');
//   const [bannerImage, setBannerImage] = useState('/images/iinvestment_.jpg'); // State to store background image

//   const handleClick = () => {
//     if (status === 'connect') {
//       setStatus('pending');
//     }
//   };

//   const handleAccept = () => {
//     if (status === 'pending') {
//       setStatus('message');
//     }
//   };

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const newImageUrl = URL.createObjectURL(file);
//       setBannerImage(newImageUrl);
//     }
//   };

//   return (
//     <div className="profile-page22">
//       <div className="top-banner22" style={{ backgroundImage: `url(${bannerImage})` }}>
//         <input
//           type="file"
//           id="bg-image-input"
//           accept="image/*"
//           onChange={handleImageChange}
//           style={{ display: 'none' }}
//         />
//         <label htmlFor="bg-image-input" className="edit-icon22">
//           ✏️
//         </label>
//         <div className="banner-text22">
//           <h1>FOCUS YOUR INVESTMENT</h1>
//           <p className="free122">Feel Free to Connect</p>
//         </div>
//       </div>

//       {/* Investor Profile Section */}
//       <div className="container22">
//         {/* Left Section */}
//         <div className="left-section22">
//           <div className="detail-section22">
//             <h4 className="section-title22">INVESTOR TYPE</h4>
//             <p>Others</p>
//             <hr />
//           </div>
//           <div className="detail-section22">
//             <h4 className="section-title22">PREFERRED INVESTMENT STAGES</h4>
//             <p>Seed</p>
//             <hr />
//           </div>
//           <div className="detail-section22">
//             <h4 className="section-title22">LEVEL</h4>
//             <p>Passive Investor</p>
//             <hr />
//           </div>


//           <div className="detail-section22">
//             <h4 className="section-title22">FOCUS AREA'S</h4>
//             <p>Consumer Internet, Enterprise</p>
//             <hr />
//           </div>
//           <div className="detail-section22">
//             <h4 className="section-title22">INVESTMENT RANGE</h4>
//             <p>50 Lacs - 2 Crore INR</p>
//             <hr />
//           </div>
//           <div className="detail-section22">
//             <h4 className="section-title22">LOCATION</h4>
//             <p>Surat, Gujarat</p>
//             <hr />
//           </div>
//         </div>

//         {/* Right Section */}
//         <div className="right-section22">
//           <div className="profile-info22">
//             <img
//               src="/images/avator.png"
//               alt="Investor Avatar"
//               className="avatar22"
//             />
//             <h2 className="free22">Robert Wilson</h2>
//             <p>Level: Explorer</p>
//             <div className="rating22">
//               {[...Array(5)].map((_, index) => (
//                 <span key={index}>⭐</span>
//               ))}
//             </div>
//             <p>
//               <i className="fa fa-phone22"></i> +91 - 63853 29793
//             </p>
//             <p>
//               <i className="fa fa-envelope22"></i> xyz310@gmail.com
//             </p>
//           </div>

//           {/* About Section */}
//           <div className="about-section22">
//             <h3>About</h3>
//             <p>
//               Robert Wilson is a dynamic investment firm committed to fostering
//               innovation and supporting visionary entrepreneurs across India. As
//               a key player in the venture capital ecosystem, we focus on
//               identifying and nurturing early-stage startups with high-growth
//               potential. Our mission is to empower startups that are shaping the
//               future with innovative solutions, disruptive business models, and a
//               passion for excellence.
//             </p>
//           </div>

//           {/* Tie-up Section */}
//           <div
//             className={`tie-up-section22 ${hovered ? 'hovered' : ''}`}
//             onMouseEnter={() => setHovered(true)}
//             onMouseLeave={() => setHovered(false)}
//           >
//             <h3>Tie up with companies</h3>
//             <h2>450+</h2>
//             <div className="handshake-icon22">🤝</div>


//           </div>
//           <div className='linkmsg34'>
//               {status === 'connect' && (
//                 <button className="invest-floating-connect-btn" onClick={handleClick}>
//                   <span className="invest-icon">👤+Connect</span>
//                 </button>
//               )}
//               {status === 'pending' && (
//                 <button className="invest-floating-pending-btn" onClick={handleAccept}>
//                   <span className="invest-icon">⏳Pending</span>
//                 </button>
//               )}
//               {status === 'message' && (
//                 <button className="invest-floating-message-btn">
//                   <span className="invest-icon">✉️Message</span>
//                 </button>
//               )}
//             </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default InvestorProfile;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook
import './InvestorProfile.css';

const InvestorProfile = () => {
  const [bannerImage, setBannerImage] = useState('/images/iinvestment_.jpg');
  const [investorData, setInvestorData] = useState(null);
  const [status, setStatus] = useState('connect');
  const [hovered, setHovered] = useState(false); // Add hovered state

  const { investorId } = useParams(); // Ensure that investorId is correctly passed from the URL

  useEffect(() => {
    const fetchInvestorProfile = async () => {
      try {
        console.log('Fetching profile for investorId:', investorId);
        const response = await fetch(`https://back.startupconnectz.com/api/investors/investor/${investorId}`);

        if (!response.ok) {
          throw new Error('Failed to fetch investor profile');
        }

        const data = await response.json();
        console.log('Investor Data:', data); // Log the fetched data
        setInvestorData(data.investor);
      } catch (error) {
        console.error('Error fetching investor profile:', error);
      }
    };

    if (investorId) {
      fetchInvestorProfile();
    } else {
      console.error("No investorId found in URL.");
    }
  }, [investorId]);


  const handleClick = () => {
    if (status === 'connect') {
      setStatus('pending');
    }
  };

  const handleAccept = () => {
    if (status === 'pending') {
      setStatus('message');
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const newImageUrl = URL.createObjectURL(file);
      setBannerImage(newImageUrl);
    }
  };

  return (
    <div className="profile-page22">
      {/* Banner Section
      <div className="top-banner22" style={{ backgroundImage: `url(${bannerImage})` }}>
        <input
          type="file"
          id="bg-image-input"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
        />
        <label htmlFor="bg-image-input" className="edit-icon22">
          ✏️
        </label>
        <div className="banner-text22">
          <h1>FOCUS YOUR INVESTMENT</h1>
          <p className="free122">Feel Free to Connect</p>
        </div>
      </div> */}

      {/* Investor Profile Section */}
      <div className="container22">
        {/* Left Section */}
        <div className="left-section22">
          <div className="detail-section22">
            <h4 className="section-title22">INVESTOR TYPE</h4>
            <p>{investorData?.category || 'Not available'}</p>
            <hr />
          </div>
          <div className="detail-section22">
            <h4 className="section-title22">PREFERRED INVESTMENT STAGES</h4>
            <p>{investorData?.investmentStage || 'Not available'}</p>
            <hr />
          </div>
          <div className="detail-section22">
            <h4 className="section-title22">LEVEL</h4>
            <p>{investorData?.investmentLevel || 'Not available'}</p>
            <hr />
          </div>

          <div className="detail-section22">
            <h4 className="section-title22">FOCUS AREA'S</h4>
            <p>{investorData?.focusAreas.join(', ') || 'Not available'}</p>
            <hr />
          </div>
          <div className="detail-section22">
            <h4 className="section-title22">INVESTMENT RANGE</h4>
            <p>{investorData?.investmentAmount || 'Not available'}</p>
            <hr />
          </div>
          <div className="detail-section22">
            <h4 className="section-title22">LOCATION</h4>
            <p>{investorData?.location || 'Not available'}</p>
            <hr />
          </div>
        </div>

        {/* Right Section */}
        <div className="right-section22">
          <div className="profile-info22">
            <img
              src={`https://back.startupconnectz.com/${investorData?.profilePicture}` || '/images/avator.png'}
              alt="Investor Avatar"
              className="avatar22"
            />
            <h2 className="free22">{investorData?.name || 'Investor Name'}</h2>
            <p>{investorData?.investmentLevel || 'Not available'}</p>
           
            <p>
              <i className="fa fa-phone22"></i> {investorData?.phone || 'Not available'}
            </p>
            <p>
              <div className="email-container">
                <i className="fa fa-envelope22"></i>
                <span className="email-text">{investorData?.email || 'Not available'}</span>
              </div>
            </p>
            <div className="rating22">
              {[...Array(5)].map((_, index) => (
                <span key={index}>⭐</span>
              ))}
            </div>
          </div>

          {/* About Section */}
          <div className="about-section22">
            <h3>About</h3>
            <p>
              {investorData?.about || 'Investors are individuals or entities that allocate capital to businesses or projects with the expectation of generating financial returns. In the startup ecosystem, investors play a crucial role by providing the necessary funding to support innovation, growth, and expansion.'}
            </p>
          </div>

          {/* Tie-up Section */}
          <div className={`tie-up-section22 ${hovered ? 'hovered' : ''}`}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <h3>Tie up with companies</h3>
            <h2>{investorData?.tieUpCount || '0'}+</h2>
            <div className="handshake-icon22">🤝</div>
          </div>
          <div className='linkmsg34'>
            {status === 'connect' && (
              <button className="invest-floating-connect-btn" onClick={handleClick}>
                <span className="invest-icon">👤+Connect</span>
              </button>
            )}
            {status === 'pending' && (
              <button className="invest-floating-pending-btn" onClick={handleAccept}>
                <span className="invest-icon">⏳Pending</span>
              </button>
            )}
            {status === 'message' && (
              <button className="invest-floating-message-btn">
                <span className="invest-icon">✉️Message</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorProfile;


