
import './MentorProfile.css';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaHandshake } from "react-icons/fa";

const MentorProfile = () => {
  const { mentorId } = useParams(); // Getting mentorId from the URL params
  const [mentorData, setMentorData] = useState(null);
  const [activeSection, setActiveSection] = useState('Overview');
  const [bannerImage, setBannerImage] = useState('/images/iinvestment_.jpg');
  const [status, setStatus] = useState('connect');

  useEffect(() => {
    if (!mentorId) return; // If there's no mentorId, stop the function.

    const fetchMentorProfile = async () => {
      try {
        const response = await fetch(`https://back.startupconnectz.com/api/mentors/mentor/${mentorId}`, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch mentor profile");
        }

        const data = await response.json();
        setMentorData(data.mentor); // Set the mentor data received from the API
      } catch (error) {
        console.error("Error fetching mentor profile:", error);
      }
    };

    fetchMentorProfile(); // Call the function to fetch the mentor profile data
  }, [mentorId]); // Trigger useEffect when mentorId changes

  const handleClick = () => {
    if (status === 'connect') setStatus('pending');
  };

  const handleAccept = () => {
    if (status === 'pending') setStatus('message');
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) setBannerImage(URL.createObjectURL(file));
  };

  const activeMonths = Math.max(0, Math.min(mentorData?.activeMonths || 0, 5));

  return (
    <div className='maincontainer96'>
      {/* <div className="top-banner22" style={{ backgroundImage: `url(${bannerImage})` }}>
        <input type="file" id="bg-image-input" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
        <label htmlFor="bg-image-input" className="edit-icon22">✏️</label>
        <div className="banner-text22">
          <h1>Learning from someone who wants you to grow</h1>
          <p className="free122">Feel Free to Connect</p>
        </div>
      </div> */}

      <div className="merchant-layout96">
        <div className="left-section96">
          <h3>Mentor Information</h3>
          <p><strong>Network:</strong> {mentorData?.network || "N/A"}</p>
          <p><strong>Location:</strong> {mentorData?.city || "N/A"}, {mentorData?.state || "N/A"}</p>
          <p><strong>Email:</strong> {mentorData?.email || "N/A"}</p>
          <div>
            {status === 'connect' && <button className="-bot197-floating-connect-btn96" onClick={handleClick}>👤+Connect</button>}
            {status === 'pending' && <button className="-bot197-floating-pending-btn96" onClick={handleAccept}>⏳Pending</button>}
            {status === 'message' && <button className="-bot197-floating-message-btn96">✉️Message</button>}
          </div>
        </div>

        <div className="center-section96">
          <div className="header-tabs96">
            {['Overview', 'Point of Contact', 'Review and Ratings'].map((section) => (
              <button key={section} className={`tab-button96 ${activeSection === section ? 'active' : ''}`} onClick={() => setActiveSection(section)}>
                {section}
              </button>
            ))}
          </div>
          <div className="section-content96">
            {activeSection === 'Overview' && <div className="overview-section96"><h2>About Product</h2><p>{mentorData?.brief || "N/A"}</p></div>}
            {activeSection === 'Point of Contact' && (
              <div className="contact-section96">
                <h3>Point of Contact</h3>
                <p>
                  Business Name: {mentorData?.startupName || "N/A"} <br />
                  Phone Number: {mentorData?.mobile || "N/A"} <br />
                  Email Address: {mentorData?.email || "N/A"} <br />
                  Location: {mentorData?.city || "N/A"}, {mentorData?.state || "N/A"} <br />
                  Website: {mentorData?.websiteLink || "N/A"} <br />
                  Hub Profile: {mentorData?.hubProfileLink || "N/A"} <br />
                </p>
              </div>
            )}
            {activeSection === 'Review and Ratings' && (
              <div className="review-section96">
                <h3>Review and Ratings</h3>
                <p>Ratings: {'★'.repeat(activeMonths)}{'☆'.repeat(5 - activeMonths)} (4/5)</p>
                <p>Reviews: "Mentor with vast experience and great insight into startup growth!"</p>
              </div>
            )}
          </div>
        </div>

        <div className="merchant-right96">
          <div className="profile-info96">
            <div className="image-container85">
              <img src={mentorData?.file ? `https://back.startupconnectz.com/${mentorData.file}` : "/images/default.jpg"} alt={`${mentorData?.mentorName || "Mentor"}'s profile`} className="profile-image85" />
            </div>
            <h3>{mentorData?.mentorName || "N/A"}</h3>
            <p>Engagement Level:</p>
            <p>{'★'.repeat(activeMonths)}{'☆'.repeat(5 - activeMonths)}</p>
            <div className="contact-info96">
              <p><i className="fa fa-phone96"></i> {mentorData?.mobile || "N/A"}</p>
              <p><i className="fa fa-envelope96"></i> {mentorData?.email || "N/A"}</p>
            </div>
          </div>
          <div className='centerbottom96'>
        <FaHandshake className="handshake-icon96" />
        <p>Tie up with Companies</p>
        <br />
        <div className="counter-container96">
            <span id="counter96">{mentorData?.activeMonths || "0"}</span>
        </div>
    </div>
        </div>
      </div>

  

    </div>
  );
};

export default MentorProfile;
