import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationPage from './NotificationPage';
import './Header.css';
import 'font-awesome/css/font-awesome.min.css';
import { MdAddLocationAlt, MdSettingsVoice, MdPersonSearch } from "react-icons/md";


const Header = ({ isLoggedIn, setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [showNotifications, setShowNotifications] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [location, setLocation] = useState('');
  const [tempLocation, setTempLocation] = useState(''); // Temporary input state
  const [isEditingLocation, setIsEditingLocation] = useState(false); // Toggle input mode
  const [keyword, setKeyword] = useState('');

  // Example useEffect to log changes
  useEffect(() => {
    console.log('Location or keyword updated:', { location, keyword });
  }, [location, keyword]);

  const handleSearch = () => {
    console.log('Location:', location);
    console.log('Keyword:', keyword);
    // Add your search logic here
  };

  const handleVoiceInput = () => {
    console.log('Voice input triggered');
    // Add voice recognition logic here
  };

  const handleLocationClick = () => {
    setIsEditingLocation(true); // Switch to editing mode
    setTempLocation(location); // Pre-fill with current location
  };

  const handleLocationBlur = () => {
    if (tempLocation.trim()) {
      setLocation(tempLocation); // Confirm and save location
    }
    setIsEditingLocation(false); // Switch to display mode
  };


  const handleCategoryClick = (path) => {
    setIsSidebarOpen(false); // Close the sidebar
    navigate(path);
  };
  const [profileImage, setProfileImage] = useState("/images/avatar.jpeg"); // Default image


  useEffect(() => {
    // On component mount, check if the user is logged in
    const storedLoginState = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(storedLoginState === "true"); // Convert string to boolean

    // Listen for page unload or refresh to reset the login state
    const handleUnload = () => {
      localStorage.setItem("isLoggedIn", "false"); // Reset login state
    };

    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [setIsLoggedIn]);



  useEffect(() => {
    // Retrieve profile image from localStorage
    const storedImage = localStorage.getItem("profileImage");
    if (storedImage) {
      setProfileImage(storedImage);
    }
  }, []);

  const notifications = [
    { message: 'You have a new startup request from XYZ Corp. Click to review.', date: '2024-10-30' },
    { message: 'Your application for ABC Startup has been approved! Welcome aboard!', date: '2024-10-29' },
    { message: 'Don’t miss out! Join our webinar on startup funding this Friday.', date: '2024-10-28' },
    { message: 'Reminder: Complete your profile for better matches with investors.', date: '2024-10-27' },
    { message: 'New features added to the platform. Check them out now!', date: '2024-10-26' },
  ];


  const recognitionCategories = [
    { name: 'Student Recognition and Benefits', path: '/Dpiit2' },
    // { name: 'Apply for DPIIT Recognition', path: '/Dpiit1' },
    { name: 'Verify/Download Certificate', path: '/Form' },
    // { name: 'Self Certification', path: '/SelfCertification' },
    { name: 'Regulatory Updates', path: '/RegulationsTable' },
    // { name:  'IncomeTaxExemption', path:'/IncomeTaxExemption'},
    { name: 'ApplicationStatus', path: '/application' },
  ];

  const handleMouseEnter = (dropdown) => setActiveDropdown(dropdown);
  const handleMouseLeave = () => setActiveDropdown(null);


  const handleLoginClick = () => navigate('/login');
  const handleLogoutClick = () => {
    const confirmLogout = window.confirm("Are you sure you want to log out?");

    if (!confirmLogout) return; // If the user clicks "Cancel", do nothing

    localStorage.setItem("isLoggedIn", "false"); // Update stored login state
    setProfileImage("/images/avatar.jpeg");
    setIsLoggedIn(false);
    navigate('/login'); // Redirect to login page
  };

  const handleDashboardClick = () => {
    navigate('/dashboard');
  }

  const handleRegisterClick = () => navigate('/register');

  const handleNotificationClick = () => setShowNotifications(true);
  const handleCloseNotifications = () => setShowNotifications(false);

  return (
    <>
      <header className="header123">
        <div className="logo">
          <img src="/images/Startup-connectz copy.png" alt="Logo" />
          {/* <div className="logo-text">
    <h1>
      <span className="connect">Startup Connect</span>
     
    </h1>
    <p>Feel Free To Connect</p>
  </div> */}
        </div>

        <div className="menu-buttons">
          <div className="search-bar-66">
            
            {/* Location Input */}
            <div
              className="input-container-J99 location-container-J99"
              onClick={!isEditingLocation ? handleLocationClick : undefined}
            >
              <MdAddLocationAlt className="icon-J99 location-icon-J99" />
              {isEditingLocation ? (
                <input
                  type="text"
                  value={tempLocation}
                  onChange={(e) => setTempLocation(e.target.value)}
                  onBlur={handleLocationBlur}
                  autoFocus
                  className="input-field-J99 location-input-J99"
                  placeholder="Enter Location"
                />
              ) : (
                <span className="location-display-J99">
                  {location || ' Location'}
                </span>
              )}
            </div>

            {/* Divider Line */}
            <div className="divider-J99"></div>

            {/* Keyword Input */}
            <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Search here"
              className="input-field-J99 keyword-input-J99"
            />

            {/* Voice Icon */}
            <button
              onClick={handleVoiceInput}
              className="button-J99 voice-button-J99"
            >
              <MdSettingsVoice className="icon-J99 voice-icon-J99" />
            </button>

            {/* Search Button */}
            <button
              onClick={handleSearch}
              className="button-J99 search-button-J99"
            >
              <MdPersonSearch className="icon-J99 search-icon-J99" />
            </button>
          </div>

          <div className="profile-pic" onClick={handleDashboardClick}>
            <img src={profileImage} alt="Profile" />
          </div>
          <button
            className="menu-button"
            onClick={isLoggedIn ? handleLogoutClick : handleLoginClick}
          >
            {isLoggedIn ? 'Logout' : 'Login'}
          </button>
          {!isLoggedIn && (
            <button className="menu-button" onClick={handleRegisterClick}>
              Register
            </button>
          )}
          <div className="notification-icon" onClick={handleNotificationClick}>
            <i className="fa fa-bell" aria-hidden="true"></i>
          </div>
        </div>
      </header>
      <div className="hamburger" onClick={toggleSidebar}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <button className="close-btn" onClick={toggleSidebar}>
          &times;
        </button>
        <nav className="sidebar-links">
          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/home");
            }}
          >
            Home
          </button>
          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/aboutus");
            }}
          >
            About
          </button>

          {/* Recognition Dropdown */}
          <div
            className="sidebar-link-dropdown"
            onMouseEnter={() => handleMouseEnter("recognitionCategories")}
            onMouseLeave={handleMouseLeave}
          >
            <button className="sidebar-link">Student Recognition</button>
            {activeDropdown === "recognitionCategories" && (
              <div className="dropdown-menu">
                {recognitionCategories.map((category, index) => (
                  <div
                    key={index}
                    className="dropdown-item"
                    onClick={() => handleCategoryClick(category.path)}
                  >
                    {category.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/startup");
            }}
          >
            Student StartUp
          </button>
          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/InvestorCont1");
            }}
          >
            Investor
          </button>
          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/Mentorlist");
            }}
          >
            Mentor
          </button>

          <button
            className="sidebar-link"
            onClick={() => {
              setIsSidebarOpen(false);
              navigate("/contactus");
            }}
          >
            Help & Support
          </button>
        </nav>
      </div>
      <nav className="nav-links">
        <button className="nav-link" onClick={() => navigate('/home')}>Home</button>
        <button className="nav-link" onClick={() => navigate('/aboutus')}>About</button>
        <div
          className="nav-link-dropdown"
          onMouseEnter={() => handleMouseEnter('recognitionCategories')} // Ensure the key matches
          onMouseLeave={handleMouseLeave}
        >
          <button className="nav-link">Student Recognition</button>

          {activeDropdown === 'recognitionCategories' && (
            <div className="dropdown-menu">
              {recognitionCategories.map((category, index) => (
                <div
                  key={index}
                  className="dropdown-item"
                  onClick={() => handleCategoryClick(category.path)}
                >
                  {category.name}
                </div>
              ))}
            </div>
          )}
        </div>

        <button className="nav-link" onClick={() => navigate('/startup')}>Student StartUp</button>
        <button className="nav-link" onClick={() => navigate('/InvestorCont1')}>Investor</button>
        <button className="nav-link" onClick={() => navigate('/Mentorlist')}>Mentor</button>


        <button className="nav-link" onClick={() => navigate('/contactus')}>
          Help & Support
        </button>
      </nav>

      {showNotifications && (
        <NotificationPage notifications={notifications} onClose={handleCloseNotifications} />
      )}
    </>
  );
};

export default Header;