import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./settings.css";

const Settings = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    website: "",
    appLink: "",
    industry: "",
    state: "",
    city: "",
    location: "",
    investmentAmount: "",
    focusAreas: "",
    investmentStage: "",
    investmentLevel: "",
    phone: "", // Separate field for Investor phone number
  });

  const userType = localStorage.getItem("userType");
  const token = localStorage.getItem("authToken");

  useEffect(() => {
    if (!token) {
      navigate("/login");
      return;
    }
    fetchCurrentData();
  }, []);
  
  const backclick = () => {
    navigate("/dashboard");
  };

  const fetchCurrentData = async () => {
    const endpoints = {
      Startup: "/api/users/startup-profile",
      Mentor: "/api/users/mentor-profile",
      Investor: "/api/users/investor-profile",
    };

    const endpoint = endpoints[userType];
    if (!endpoint) {
      console.error("Invalid user type");
      navigate("/login");
      return;
    }

    try {
      const response = await fetch(`https://back.startupconnectz.com${endpoint}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) throw new Error("Failed to fetch current profile data");

      const data = await response.json();

      const defaultData = {
        email: data.user?.email || "",
        mobile: data[userType.toLowerCase()]?.mobile || "",
        website: data[userType.toLowerCase()]?.website || "",
        appLink: data[userType.toLowerCase()]?.appLink || "",
        industry: data[userType.toLowerCase()]?.industry || "",
        state: data[userType.toLowerCase()]?.state || "",
        city: data[userType.toLowerCase()]?.city || "",
      };

      if (userType === "Investor") {
        setFormData({
          ...defaultData,
          phone: data.investor?.contactInfo?.phone || "", // Specific field for Investor's phone
          location: data.investor?.contactInfo?.location || "",
          investmentAmount: data.investor?.interest?.investmentAmount || "",
          focusAreas: data.investor?.interest?.focusAreas?.join(", ") || "",
          investmentStage: data.investor?.interest?.investmentStage || "",
          investmentLevel: data.investor?.interest?.investmentLevel || "",
        });
      } else {
        setFormData(defaultData);
      }
    } catch (error) {
      console.error("Error fetching current data:", error);
      alert("Failed to load settings data.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoints = {
      Startup: "/api/users/startup-profile",
      Mentor: "/api/users/mentor-profile",
      Investor: "/api/users/investor-profile",
    };

    const endpoint = endpoints[userType];
    if (!endpoint) {
      console.error("Invalid user type");
      return;
    }

    try {
      const response = await fetch(`https://back.startupconnectz.com${endpoint}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error("Failed to update profile");

      alert("Profile updated successfully!");
      navigate("/profile");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile. Please try again.");
    }
  };

  return (
    <div className="settings-containerlk1">
      <h1>Settings</h1>
      <form onSubmit={handleSubmit} className="settings-formlk1">
        {/* Shared Fields */}
        <label>Email:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>

        {/* Hide Mobile for Investor */}
        {userType !== "Investor" && (
          <label>Mobile:
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
          </label>
        )}

        {/* Startup Fields */}
        {userType === "Startup" && (
          <>
            <label>Website:
              <input
                type="url"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />
            </label>
            <label>App Link:
              <input
                type="url"
                name="appLink"
                value={formData.appLink}
                onChange={handleChange}
              />
            </label>
            <label>Industry:
              <input
                type="text"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
              />
            </label>
            <label>State:
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
            </label>
            <label>City:
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
            </label>
          </>
        )}

        {/* Mentor Fields */}
        {userType === "Mentor" && (
          <>
            <label>Website:
              <input
                type="url"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />
            </label>
            <label>Industry:
              <input
                type="text"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
              />
            </label>
          </>
        )}

        {/* Investor Fields */}
        {userType === "Investor" && (
          <>
            <label>Phone:
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </label>

            <label>Location:
              <input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
              />
            </label>
            <label>Investment Amount:
              <input
                type="text"
                name="investmentAmount"
                value={formData.investmentAmount}
                onChange={handleChange}
              />
            </label>
            <label>Focus Areas (Comma-Separated):
              <input
                type="text"
                name="focusAreas"
                value={formData.focusAreas}
                onChange={handleChange}
              />
            </label>
            <label>Investment Stage:
              <select
                name="investmentStage"
                value={formData.investmentStage || ""}
                onChange={handleChange}
              >
                <option value="">Select Stage</option>
                <option value="Seed">Seed</option>
                <option value="Series A">Series A</option>
                <option value="Series B">Series B</option>
                <option value="Growth">Growth</option>
              </select>
            </label>
            <label>Investment Level:
              <select
                name="investmentLevel"
                value={formData.investmentLevel || ""}
                onChange={handleChange}
              >
                <option value="">Select Category</option>
                <option value="Beginner">Beginner</option>
                <option value="Intermediate">Intermediate</option>
                <option value="Expert">Expert</option>
                <option value="Other">Other</option>
              </select>
            </label>
          </>
        )}

        <button type="submit" className="save-btnlk1">
          Save Changes
        </button>
      </form>
      <button className="back-btn" onClick={backclick}>
        Back
      </button>
    </div>
  );
};

export default Settings;