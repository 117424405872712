import React, { useState, useEffect } from 'react';
import './Disclaimer.css'; // Ensure you have styling
import { FaInfoCircle, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Disclaimer = ({ onClose }) => {
  const [disclaimer, setDisclaimer] = useState('');

  useEffect(() => {
    // Fetch the disclaimer text from the backend
    fetch('https://back.startupconnectz.com/api/admin/disclaimer')
      .then((response) => response.json())
      .then((data) => {
        setDisclaimer(data.text); // Set the fetched disclaimer text
      })
      .catch((err) => {
        console.error('Error fetching disclaimer:', err);
      });
  }, []);

  return (
    <div className="disclaimer-overlay67"> {/* Background overlay */}
      <div className="disclaimer-modal67"> {/* Centered popup modal */}
        <button className="close-btn67" onClick={onClose}> {/* Close Button */}
          <FaTimes />
        </button>
        <div className="disclaimer-content67">
          <h2>Welcome to Startup Connect</h2>
          <h3>
            <FaInfoCircle style={{ marginRight: '8px', color: '#ff6600' }} />
            Disclaimer
          </h3>
          <hr />
          {/* Display disclaimer text if available, else leave it blank */}
          <p>{disclaimer || null}</p>

          {/* Only show the link if the disclaimer is set */}
          {disclaimer && (
            <Link to="/Kairaa78" style={{ textDecoration: 'none' }} className="know-more-btn67">
              <button onClick={onClose}>KNOW MORE </button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
