import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'; // Import cooler eye icons
import './ResetPassword.css';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { id, token } = useParams();

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!password || !confirmPassword) {
      setError('Both fields are required');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    axios
      .post(`https://back.startupconnectz.com/api/users/reset-password/${id}/${token}`, { password })
      .then((res) => {
        if (res.data.Status === 'Success') {
          alert('Password Updated');
          navigate('/login');
        } else {
          setError(res.data.Message || 'An error occurred.');
        }
      })
      .catch((err) => {
        console.log(err);
        setError('Failed to reset password. Please try again later.');
      });
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-form-wrapper">
        <h2 className="reset-password-title">Reset Your Password</h2>
        <form onSubmit={handleSubmit} className="reset-password-form">
          <div className="reset-password-form-group">
            <label className="reset-password-form-label">New Password:</label>
            <div className="reset-password-input-wrapper">
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                placeholder="Enter new password"
                className="reset-password-form-input"
              />
              {showPassword ? (
                <MdVisibilityOff onClick={toggleShowPassword} className="reset-password-toggle-icon" />
              ) : (
                <MdVisibility onClick={toggleShowPassword} className="reset-password-toggle-icon" />
              )}
            </div>
          </div>

          <div className="reset-password-form-group">
            <label className="reset-password-form-label">Confirm Password:</label>
            <div className="reset-password-input-wrapper">
              <input
                type={showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                placeholder="Confirm new password"
                className="reset-password-form-input"
              />
              {showConfirmPassword ? (
                <MdVisibilityOff onClick={toggleShowConfirmPassword} className="reset-password-toggle-icon" />
              ) : (
                <MdVisibility onClick={toggleShowConfirmPassword} className="reset-password-toggle-icon" />
              )}
            </div>
          </div>

          {error && <p className="reset-password-error-message">{error}</p>}

          <button type="submit" className="reset-password-btn">Reset Password</button>
        </form>
      </div>
    </div>

  );
}

export default ResetPassword;
