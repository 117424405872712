import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
import './homepage.css';
import axios from 'axios';
import { FaPlaneArrival } from "react-icons/fa6";
import 'react-slideshow-image/dist/styles.css'; // Import default styles
import { FaRocket, FaHandshake } from 'react-icons/fa'; // Importing React Icons
import { FaUserFriends, FaPaintBrush, FaVial, FaProjectDiagram } from 'react-icons/fa';
import { FaCalendarAlt, FaClock, FaMapMarkerAlt } from 'react-icons/fa';


const courseData = [
  {
    title: 'Discover Innovative Startups',
    images: [

      'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734079810/WhatsApp_Image_2024-12-13_at_12.30.57_PM_rdjqvy.jpg'
    ],
    description: 'Explore groundbreaking startups and foster meaningful connections with entrepreneurs.',
    icon: '🔗'
  },
  {
    title: 'Guidance and Mentorship',
    images: [
      'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734093379/best-investment-PowerPoint-template_1_gxkqx4.jpg',
      'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090880/Strategies-for-winning-investors-in-Your-Business-01-scaled_fjw9ga.jpg'
    ],
    description: 'Provide valuable industry insights and strategic direction to help startups grow.',
    icon: '🎓'
  },
  {
    title: 'Explore Investment Avenues',
    images: [
      'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090997/startup-company-scaled_me8pvv.jpg',
      'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734093379/best-investment-PowerPoint-template_1_gxkqx4.jpg'
    ],
    description: 'Find promising startups that align with your vision and investment goals.',
    icon: '💼'
  },
  {
    title: 'Customized Services',
    images: [
      'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090997/startup-company-scaled_me8pvv.jpg',
      'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734093379/best-investment-PowerPoint-template_1_gxkqx4.jpg'
    ],
    description: 'Tailored services to help you connect with the right opportunities and resources for your success.',
    icon: '💼',
    buttonText: 'Discover Services',
    link: '/customized-services',
    serviceType: 'Consulting'
  }

];


const Homepage = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [startupImage, setStartupImage] = useState(""); // To dynamically set the startup image
  const [totalStartups, setTotalStartups] = useState(0);
  const [connectedStartups, setConnectedStartups] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [mainSliderIndex, setMainSliderIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState('fade-in');
  const [fadeContentClass, setFadeContentClass] = useState('');
  const [startup, setStartup] = useState(0);
  const [investor, setInvestor] = useState(0);
  const [mentor, setMentor] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false);
  const containerRef = useRef(null);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const handleUserTypeSelection = async (userType, route) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        alert("Please log in first."); // Alert message instead of setMessage
        return;
      }

      // Send request to backend for validation
      const response = await axios.post(
        "https://back.startupconnectz.com/api/validate-selection",
        { userType },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      if (response.data.success) {

        navigate(route); // Navigate to the respective registration page
      }
    } catch (error) {
      console.error(error);
      alert("Error validating user type. Please try again later."); // Show general error alert
    }
  };



  const performSearch = () => {
    // Logic for handling the search (e.g., filter data based on searchQuery)
    console.log("Searching for:", searchQuery);
    // You can replace this with actual search logic
  };
  // Auto slideshow effect
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % courseData[0].images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Fetch events data from the backend to display
    axios.get('https://back.startupconnectz.com/api/events')  // Ensure the correct backend URL
      .then(response => {
        setEvents(response.data);
      })
      .catch(error => {
        console.error('Error fetching events:', error);
      });
  }, []);

  // Update the total startups counter
  useEffect(() => {
    const interval = setInterval(() => {
      if (totalStartups < 122) {
        setTotalStartups(prevState => prevState + 1); // functional update
      }
    }, 200);
    return () => clearInterval(interval);
  }, [totalStartups]);

  // Update the connected startups counter
  useEffect(() => {
    const interval = setInterval(() => {
      if (connectedStartups < 22) {
        setConnectedStartups(prevState => prevState + 1); // functional update
      }
    }, 150); // slower increment for connected startups
    return () => clearInterval(interval);
  }, [connectedStartups]);

  const containerVariants = {
    hidden: { opacity: 0, x: "100%" },
    visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    exit: { opacity: 0, x: "100%", transition: { duration: 0.3 } },
  };

  useEffect(() => {
    const lastClosed = localStorage.getItem('popupClosed');
    const lastPopupTime = localStorage.getItem('lastPopupTime');
    const currentTime = new Date().getTime();

    // Show popup if not closed before or if 30 minutes have passed
    if (!lastClosed || (currentTime - lastPopupTime > 30 * 60 * 1000)) {
      setIsPopupOpen(true);
    }
  }, []);

  const closePopup = () => {
    setIsPopupOpen(false);
    localStorage.setItem('popupClosed', 'true'); // Store popup closed state
    localStorage.setItem('lastPopupTime', new Date().getTime()); // Store the time when the popup was closed
  };



  const mainSliderImages = useMemo(() => [
    'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734079810/WhatsApp_Image_2024-12-13_at_12.30.57_PM_rdjqvy.jpg',
    'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734093379/best-investment-PowerPoint-template_1_gxkqx4.jpg',
    'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090880/Strategies-for-winning-investors-in-Your-Business-01-scaled_fjw9ga.jpg',
    'https://res.cloudinary.com/dmoweu5mh/image/upload/v1734090997/startup-company-scaled_me8pvv.jpg'
  ], []); // No dependencies, this array will remain the same



  useEffect(() => {
    const timer = setInterval(() => {
      setMainSliderIndex((prevIndex) => (prevIndex + 1) % mainSliderImages.length);
      setFadeClass('');
      setFadeContentClass(''); // Start with content hidden

      setTimeout(() => {
        setFadeClass('fade-in');
        setFadeContentClass('fade-in'); // Show content after image change
      }, 500); // Delay to match the slide transition
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, [mainSliderIndex]);

  // Function to animate values
  const animateValue = (start, end, duration, setter) => {
    let startTime;
    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const value = Math.min(start + (end - start) * (progress / duration), end);
      setter(Math.floor(value));
      if (progress < duration) {
        requestAnimationFrame(step);
      }
    };
    requestAnimationFrame(step);
  };
  // Fetch data from API
  useEffect(() => {
    fetch("https://back.startupconnectz.com/api/users/stats")
      .then((response) => response.json())
      .then((data) => {
        setStartup(data.startups);
        setInvestor(data.investors);
        setMentor(data.mentors);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  // Intersection Observer to trigger the animation when the container comes into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !hasAnimated) {
          animateValue(0, startup, 2000, setStartup);
          animateValue(0, investor, 2000, setInvestor);
          animateValue(0, mentor, 2000, setMentor);

          setHasAnimated(true);
        }
      },
      { threshold: 0.5 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [hasAnimated, startup, investor, mentor]);

  return (
    <div className="home-container">

      <div className="containerMM1">
        <div className="main-slider-wrapper">
          <div className={`main-slider ${fadeClass}`}>
            <img src={mainSliderImages[mainSliderIndex]} alt="Main Slider" />
          </div>
        </div>
        <div className={`contentMM1 ${fadeContentClass}`}>
          <h1 className="headerMM1">
            We Help to Build Your <span className="highlightMM1">Startup Connections</span>.
          </h1>
          <p className="paragraphMM1">
            Startup Connect helps startups, investors, and mentors connect for collaboration, funding, and guidance to drive growth and success. By fostering meaningful partnerships and offering strategic insights, it empowers startups to scale, innovate, and thrive in a competitive market.
          </p>
          <div className="buttonsMM1">
            <Link to="/RegistrationHome"><button className="get-startedMM1">Get Started</button></Link>
            <Link to="/Kairaa78"><button className="our-servicesMM1">Our Services</button></Link>
          </div>
          <div className="statsMM1">
            <div className="statMM1">
              <FaRocket size={40} color="#ff6600" />
              <motion.h2>{totalStartups}</motion.h2>
              <p>Total Startups</p>
            </div>
            <div className="statMM1">
              <FaHandshake size={40} color="#ff6600" />
              <motion.h2>{connectedStartups}</motion.h2>
              <p>Connected Startups</p>
            </div>
          </div>
        </div>
      </div>

      <div className="homepage-container-hm22">
        {isPopupOpen && (
          <motion.div
            className="popup-overlay-hm22"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={containerVariants}
            onClick={closePopup}
          >
            <motion.div
              className="popup-container-hm22 advanced-popup-hm22"
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
            >
              <button className="close-button-hm22" onClick={closePopup}>
                &times;
              </button>
              <div className="popup-content-hm22">
                <h2 className="popup-title-hm22">
                  <FaPlaneArrival /> New Startup Arrival
                </h2>
                <div className="startup-image-container-hm22">
                  {startupImage && <img src={startupImage} alt="New Startup" className="startup-image-hm22" />}
                </div>
                <p className="popup-description-hm22">
                  Check out the latest innovative startup to join our platform.
                </p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </div>


      <div className="about-me-container">
        <div className="about-text">
          <h1><h2>Key</h2> <strong>Initiatives</strong> </h1>
          <img src="\images\key initiative copy.jpg" alt="Key Initiatives" className="about-image" />
          <p>
            Startup Connect brings entrepreneurs, investors, and mentors together for networking and collaboration.
            Users can create profiles, showcase businesses, and connect based on their needs.
            Premium plans offer exclusive tools and advanced networking options.
          </p>

        </div>
        <div className="process-flow">

          <div className="process-box">
            <FaUserFriends className="process-icon" />
            <h3>Build Your Profile </h3>
            <p>Create a detailed profile to highlight your business and expertise.</p>
          </div>
          <div className="process-box">
            <FaPaintBrush className="process-icon" />
            <h3>Connect & Network      </h3>
            <p>Engage with investors, mentors, and startups to expand your opportunities.</p>
          </div>
          <div className="process-box">
            <FaVial className="process-icon" />
            <h3>Collaborate for Growth</h3>
            <p>Form partnerships and collaborations to drive mutual success. </p>
          </div>
          <div className="process-box">
            <FaProjectDiagram className="process-icon" />
            <h3>Exclusive Tools</h3>
            <p>Unlock advanced tools with premium plans to enhance your networking.</p>
          </div>
        </div>

      </div>

      <div className="services-containerFF44">
        <div className="services-headerFF44">
          <h2 className="services-titleFF44">Services We Offer</h2>
          <p className="services-subtitleFF44">
            <strong>Explore the services at Startup Connect, designed to help your startup grow.</strong>
            Connect with investors, mentors, and like-minded startups, discover opportunities, track your progress, and access valuable resources. Start your entrepreneurial journey with us today!
          </p>
        </div>

        <motion.div
          className="services-gridFF44"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {courseData.map((course, index) => (
            <motion.div
              key={`${course.title}-${index}`}
              className="service-cardFF44"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <motion.img
                src={course.images[0]}  // Assuming currentImageIndex is 0 or passed as a prop
                alt={course.title}
                className="service-imageFF44"
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.3 }}
              />
              <div className="service-contentFF44">
                <h3>{course.title}</h3>
                <p>{course.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>

      <div className="events-container1123">
        <h2 className="events-title1123">Upcoming Events & Webinars</h2>
        <div className="events-grid1123">
          {events.length === 0 ? (
            <p>No events available at the moment. Please check back later.</p>
          ) : (
            events.map((event) => (
              <div className="event-card1123" key={event._id}>
                <div className="event-header1123">
                  <FaCalendarAlt className="event-icon1123" />
                  <span className="event-date1123">{event.date}</span>
                </div>
                <h3 className="event-title1123">{event.title}</h3>
                <p className="event-description1123">{event.description}</p>
                <div className="event-info1123">
                  <p><FaClock className="event-icon1123" /> {event.time}</p>
                  <p><FaMapMarkerAlt className="event-icon1123" /> {event.location}</p>
                </div>
                <div className="event-actions1123">
                  <button className="event-btn1123">Register Now</button>
                </div>
              </div>
            ))
          )}
        </div>
      </div><br></br><br></br>

      <div className="Users" ref={containerRef}>
        <h2>Our Happy Users</h2>
        <center>
          <div className="stats-container-BB22">
            <div className="stat-BB22">
              <div className="circle-BB22">
                <span className="value-BB22">{startup}+</span>
              </div>
              <p>Successful <span className="highlight-BB22">Startups</span></p>
            </div>

            <div className="stat-BB22">
              <div className="circle-BB22">
                <span className="value-BB22">{investor}+</span>
              </div>
              <p>Trusted <span className="highlight-BB22">Investors</span></p>
            </div>

            <div className="stat-BB22">
              <div className="circle-BB22">
                <span className="value-BB22">{mentor}+</span>
              </div>
              <p>Experienced <span className="highlight-BB22">Mentors</span></p>
            </div>
          </div>
        </center>
        <br></br>
        <div className="cta-containerX77">
          <div className="cta-contentX77">
            <h2 className="cta-headingX77">Join the Revolution</h2>
            <div className="cta-buttons-containerX77">

              <div className="cta-button-containerX77">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  className="cta-buttonX77"
                  onClick={() => handleUserTypeSelection("Startup", "/StartupRegistration")}
                >
                  Register as a Startup
                </motion.button>
              </div>

              <div className="cta-button-containerX77">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  className="cta-buttonX77"
                  onClick={() => handleUserTypeSelection("Investor", "/InvestorRegistration")}
                >
                  Register as an Investor
                </motion.button>
              </div>

              <div className="cta-button-containerX77">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  className="cta-buttonX77"
                  onClick={() => handleUserTypeSelection("Mentor", "/MentorRegistration")}
                >
                  Register as a Mentor
                </motion.button>
              </div>

            </div>

            <div className="search-cta-containerX77">
              <div className="search-barX77">
                <input
                  type="text"
                  placeholder="Who are you looking for ?"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button className="search-buttonX77" onClick={performSearch}>
                  Search <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 24 24"><path d="M23 21l-5.5-5.5m0 0a9 9 0 10-13 0 9 9 0 0013 0z"></path></svg>
                </button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>


  );
}
export default Homepage;
