import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './Aboutus.css'; // Add your CSS here for styles

const Aboutus = () => {
  return (
    
      <div className="app">
        {/* Hero Section */}
        <div className="hero-container" id="hero">
          <div className="hero-wrapper">
            <div className="content-container">
              <div className="text-section" data-aos="zoom-in" data-aos-delay="200">
                <h1 className="main-title">
                Get to Know Us
                </h1>
                <div className="sub-title">
                Startup Connect fosters collaboration by connecting entrepreneurs, investors, and experts, creating a thriving ecosystem for innovation. It’s the platform where startups find guidance, funding, and opportunities for growth.
                </div>
               
                
              </div>
              <div className="image-section" data-aos="fade-up" data-aos-delay="700">
                <img alt="hero-img" className="hero-image" src="/images/web-dev.fd61237124f5b922e412437caf61ea58.svg" />
              </div>
            </div>
          </div>
        </div>

        {/* Intro Section */}
       
          
              <div className="container" id="about">
                <div className="content" data-aos="fade-up">
                  <div className="image-container">
                    <img alt="card img" className="image" src="/images/Web-developer.6c7276fff94ccaae45617195f0ef27ae.svg" />
                  </div>
                  <div className="text-content" data-aos="zoom-in" data-aos-delay="500">
                    <h3 className="title">
                    We are passionate about providing innovative solutions that help businesses thrive and succeed in an ever-evolving digital world
                    </h3>
                    <p className="subtitle">
                    Networking and Access to Funding: Startup connect offers valuable opportunities for networking, building relationships with investors, and gaining access to funding, which are essential for growth and scaling.
                    </p>
                    <p className="subtitle">
                    Knowledge Sharing and Collaboration: It fosters knowledge exchange, mentorship, and collaboration, helping startups navigate challenges, share resources, and drive innovation for more efficient business growth.
                    </p>
                    <Link to="/register" className="btn" style={{ textDecoration: 'none' }}>
                     Register Now
                    </Link>
                  </div>
                </div>
              </div>
           
    

        {/* Services Section */}
        <div id="services" className="services-section">
          <section>
            <div className="section-header876">
              <h2 className="section-title876">Why Startup Connect?</h2>
              <div className="section-divider876"></div>
              <h2 className="section-subtitle">Startup Connect is a dynamic platform driving entrepreneurial growth, business innovation, and customer satisfaction. It provides essential tools and support for startups and businesses to thrive in today’s competitive market.</h2>
            </div>

            <div className="services-grid">
              {/* Service Cards */}
              <div className="service-card">
                <div className="card-content">
                  <img alt="Web Development" className="card-image" src="/images/web development.svg" />
                  <h2 className="card-title">Advanced Search Capabilities</h2>
                  <p className="card-description">
                  Filter results by business name, category, location, customer ratings, proximity, or specific services to deliver tailored, relevant outcomes for both consumers and businesses.
                  </p>
                </div>
              </div>

              <div className="service-card">
                <div className="card-content">
                  <img alt="Mobile App Development" className="card-image" src="/images/mobile app.svg " />
                  <h2 className="card-title">Real-Time Updates</h2>
                  <p className="card-description">
                  Keep customers informed with real-time updates on business hours, new services, or promotions, ensuring up-to-date information that fosters trust and enhances customer engagement.
                  </p>
                </div>
              </div>

              <div className="service-card">
                <div className="card-content">
                  <img alt="Domain and Hosting Services" className="card-image" src="/images/domain.svg" />
                  <h2 className="card-title">Investment & Networking Opportunities</h2>
                  <p className="card-description">
                  Investor matching connects you with potential funders, pitch preparation refines presentations, and funding tracking helps monitor and manage investments for seamless financial growth and success.
                  </p>
                </div>
              </div>

             
            </div>
          </section>

          <section>
          <div className="collaboration-section">
  <div className="collaboration-grid">
    <div className="collaboration-item">
    <div className="icon">
        {/* Add your icon image source here */}
        <img src="/images/we build.webp" alt="Build Icon" /> {/* Example SVG icon */}
      </div>
      <h3 className="collaboration-title">
        We <span className="highlight">Build</span>
      </h3>
     
      <p className="collaboration-description">
      At Startup Connect, we help startups turn innovative ideas into scalable solutions, offering customized strategies, optimizing operations, and ensuring seamless execution with expert insights and cutting-edge technology for growth and efficiency.
      </p>
    </div>

    <div className="collaboration-item">
    <div className="icon">
        {/* Add your icon image source here */}
        <img src="/images/collobrating.png" alt="Collaborate Icon" /> {/* Example SVG icon */}
      </div>
      <h3 className="collaboration-title">
        We <span className="highlight">Collaborate</span>
      </h3>
      
      <p className="collaboration-description">
      At Startup Connect, we collaborate with your team to scale existing software or create custom solutions tailored to your startup’s needs, simplifying processes and enhancing efficiency through seamless, innovative technology.
      </p>
    </div>
  </div>
</div>

          </section>
        </div>

        <div className="clients-section">
            <section data-aos="fade-up">
                <div className="clients-header">
                    <h2 className="clients-title">OUR ACHIEVERS</h2>
                    <div className="section-divider876"></div>
                    <h2 className="clients-subtitle">Some of Our High Achievers</h2>
                </div>

                <div className="clients-grid" data-aos="fade-in" data-aos-delay="600">
                    <div className="client-item" >
                        <img src="/images/1.png"alt="client" />
                    </div>

                    <div className="client-item" >
                        <img src="/images/2.png" alt="client" />
                    </div>

                    <div className="client-item" >
                        <img src="/images/3.png" alt="client" />
                    </div>
                </div>
            </section>
        </div>

        <div className="cta-container876">
            <div className="cta-content876">
                <div className="cta-text-container876">
                    <div className="cta-text876">
                        <p className="cta-main-text876">Are you ready to scale your business?</p>
                        <p className="cta-sub-text876">Get in touch and let us build something amazing <span className="cta-highlight">together!</span></p>
                    </div>
                </div>

                <div className="cta-button-container">
                    <Link to="/contactus" className="cta-button" style={{ textDecoration: 'none' }} >
                        Send a message
                        
                    </Link>
                </div>
            </div>
        </div>
      </div>
    
  );
};

export default Aboutus;
