import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Disclaimer from './Disclaimer';
import LoginPage from './LoginPage';
import RegistrationPage from './RegistrationPage';
import SubscriptionPlans from './SubscriptionPlans';
import Dashboard from './dashboard';
import Homepage from './homepage';
import Aboutus from './Aboutus';
import Startup from './Startup';
import StartupProfile from './StartupProfile';
import ContactForm from './ContactForm';
import InvestorConnect from './InvestorConnect';
import InvestorProfile from './InvestorProfile';
import Kairaa from './Kairaa';
import Dpiit2 from './Dpiit2';
import Dpiit1 from './Dpiit1';
import ApplicationStatusPage from './ApplicationStatusPage';
import Form from './Form';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';
import ScrollToTop from './ScrollToTop';
import Faq from './Faq';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import RegistrationHome from './RegistrationHome';
import StartupRegistration from './StartupRegistration';
import InvestorRegistration from './InvestorRegistration';
import MentorRegistration from './MentorRegistration';
import ProfileForm from './Profile';
import Settings from './settings';
import Overview from './overview';
import RegulationsTable from './RegulationsTable';
import DPIITPage from './DPIITPage';
import DPIITFAQPage from './DPIITFAQPage';
import Mentorlist from './Mentorlist';
import MentorProfile from './MentorProfile';
import StartupProfileForm from './StartupProfileForm';
import './App.css';


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Track login state
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  useEffect(() => {
    const hasVisitedBefore = sessionStorage.getItem('hasVisitedBefore');
    if (!hasVisitedBefore) {
      setShowDisclaimer(true);
      sessionStorage.setItem('hasVisitedBefore', 'true');
    }
  }, []);

  const closeDisclaimer = () => {
    setShowDisclaimer(false);
  };

  return (
    <Router>
      {showDisclaimer && <Disclaimer onClose={closeDisclaimer} />}
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/subscriptionplan" element={<SubscriptionPlans />} />
        <Route path="/dashboard" element={isLoggedIn ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/startup" element={<Startup />} />
        <Route path="/startup/:startupId" element={<StartupProfile />} />

        <Route path='/dpiit2' element={<Dpiit2 />} />
        <Route path='/dpiit1' element={<Dpiit1 />} />
        <Route path='/application' element={<ApplicationStatusPage />} />
        <Route path='/form' element={<Form />} />
        <Route path='/Kairaa78' element={<Kairaa />} />
        <Route path='/InvestorCont1' element={<InvestorConnect />} />
        <Route path="/investor/:investorId" element={<InvestorProfile />} />
        <Route path="/contactus" element={<ContactForm />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/Faq12" element={<Faq />} />
        <Route path="/termsandcondition" element={<TermsAndConditions />} />
        <Route path="/forgetpassword1" element={<ForgotPassword />} />
        <Route path="/reset_password/:id/:token" element={<ResetPassword />} />
        <Route path="/RegistrationHome" element={<RegistrationHome />} />
        <Route path="/StartupRegistration" element={<StartupRegistration />} />
        <Route path="/InvestorRegistration" element={<InvestorRegistration />} />
        <Route path="/MentorRegistration" element={<MentorRegistration />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/overview" element={<Overview />} />
        <Route path="/profile" element={<ProfileForm />} />
        <Route path="/RegulationsTable" element={< RegulationsTable />} />
        <Route path="/Dpiitfaq" element={<DPIITFAQPage />} />
        <Route path="/mentorlist" element={<Mentorlist/>} />
        <Route path="/MentorProfile/:mentorId" element={<MentorProfile/>} />
        <Route path='/startupprofileform' element={<StartupProfileForm />} />
        
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
